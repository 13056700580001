.button_with_loader {
    color: #252525;
    font-weight: 900;
    background-color: #45FFA6;
    border: 1px solid #45FFA6;
    border-radius: 10px;
    padding: 15px 65px;
    cursor: pointer;
    transition: all 350ms ease-in-out;
    font-size: 15px;
    font-family: Proxima Nova Rg;

    &:hover {
        color: #45FFA6;
        border-color: #45FFA6;
        background-color: transparent;
    }
}

@media (max-width: 1100px) {
    .button_with_loader {
        width: 100%;
    }
}