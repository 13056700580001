.results-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-family: Nasalization Rg;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 13px;
}

.results-table th, .results-table td {
    padding: 11px;
    border: 1px solid #7a7a80;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.results-table th {
    background-color: #7a7a80;
    font-weight: bold;
}

.results-table td {
    background-color: #222;
}

.BracketResultsTable_team {
    cursor: pointer;
    transition: color 400ms ease;
    &:hover {
        color: #45ffa6;
    }
} 

.BracketResultsTable_team.playerTeam {
    color: #10d4fe;
    transition: color 400ms ease;
}

/* .results-table tr:nth-child(even) td {
    background-color: #333;
} */

.results-table tbody tr:hover td {
    background-color: #333;
}