.create_tournament_container {
    min-height: 100vh;
    width: 100%;
    font-family: Nasalization Rg;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: rgba(255, 255, 255, 1);
    padding-top: 11vh;
}


.create_tournament_header {
    font-size: 36px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        color: #10D4FE;
        margin-left: 15px;
    }
}

.cs2_mapselection_maps_title {
    font-size: 15px;
    font-weight: 300;
}

.create_tournament_main_container {
    width: 100%;
    margin-top: 50px;
}

.create-tournament-navs {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.create_tournament_form_control.option {
    background-color: #343434 !important;
}

.create_tournament_form .form-control::placeholder {
    color: #ADADAD;
}

.create_tournament_form .form-control:focus {
    color: white !important;
}

.create_tournament_form {
    width: 55%;
    margin: 20px 0 50px;
    margin-left: 30px;
    min-height: 70vh;
}
.form-floating>.form-select~label::after{
    background-color: transparent;
}
.form-floating>.form-select~label {
    color: rgb(187, 187, 187);
}
.create_tournament_info{
    font-size: 80%;
    margin: 0 0 8px 4px;
    color: #45FFA6;
    text-align: start;
    cursor: pointer;
}

.create_tournament_form_control {
    background-color: #343434;
    color: #fff;
    margin-bottom: 20px;
    border: none !important;
    border-radius: 10px;
}
.create_tournament_form_control.option {
    color: white;
}

.create_tournament_form_control.date {
    width: 82%;
    margin-bottom: 1%;
}

.create_tournament_form_control.option {
    width: 100%;
}

.create_tournament_form_control:focus {
    background-color: #343434;
    box-shadow: none;
}

.create_tournament_form_control.floating_label {
    background-color: #343434;
}

.rs-input {
    background-color: #343434 !important;
}

.rs-input-group.rs-input-group-inside {
    background-color: #343434 !important;
}

.rs-input-group.rs-input-group-inside:hover,
.rs-input-group.rs-input-group-inside:focus,
.rs-input-group.rs-input-group-inside:active,
.rs-input-group:not(.rs-input-group-disabled),
.rs-input-group.rs-input-group-inside:hover {
    border: 1px solid transparent !important;
}

.rs-picker-toggle-wrapper {
    width: 100%;
}

.description_tournament_form_control {
    height: 218px;
}

.create_tournament_checkbox {
    justify-content: flex-start;
    margin: 20px 0px;
    font-size: 16px;
    padding: 0;
}

.save_tournament_button_container {
    margin-bottom: 95px;
    display: flex;
    justify-content: center;
    width: 100%;
}
.save_tournament_button {
    width: 25%;
    font-size: 20px;
    border-radius: 11px;
    background-color: #45FFA6;
    color: #000000;
    font-weight: 600;
    padding: 10px 5px;
    border: none;
    
    transition: all 600ms ease;
    &:hover {
        background-color: #343434;
        ;
        color: #45FFA6;
    }
    
    &:active {
        background-color: #343434;
        color: #45FFA6;
    }
}

.label_with_icon {
    display: flex;
}

.image_icon {
    margin-right: 100px;
    width: 30px;
    height: 30px;
}

input#formFile.form_control {
    background-color: #343434 !important;
    border: none;
    color: #ADADAD;
    font-family: Proxima Nova Rg;
}

.create_tournament_container .nav-pills .nav-link.active {
    color: rgb(48, 48, 48);
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    background-color: #45FFA6;
}

.create_tournament_container .nav-link {
    color: rgb(248, 248, 248);
    text-align: center;
    padding: 5px 7px;
    text-decoration: none;
}

.time_management_create_tournament_group_label {
    text-align: center;
}

.time_management_create_tournament_group {
    display: flex;
}

.create_tournament_form_control.time {
    width: 50%;
    height: 60px;
}

.create_tournament_form_control.hours {
    width: 30%;
    height: 60px;
}

.time_management_create_tournament_group_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.time_management_create_tournament_group_container.time{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}
.time-picker{
    width: 47%;
}

/*----------------- MAPS ------------- */

.сs2_mapselection_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.сs2_mapselection_container_header {
    font-size: 18px;
    margin-bottom: 20px;
}

.сs2_mapselection_maps {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.сs2_mapselection_maps div {
    width: 47.2%;
    height: 115px;
    margin: 7px;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: all 700ms ease;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 600ms ease;
    border: transparent 2px solid;

    &:hover {
        border-color: #45FFA6;
    }
}

.сs2_mapselection_maps div.selected {
    border: 2px solid rgb(17, 197, 17);
}

.сs2_mapselection_maps_title {
    font-size: 15px;
    margin: 0;
    margin-bottom: 0.5%;
    margin-left: 1.5%;
}


/*----------------- TOURNAMENT-AVATAR ------------- */


.createTournament_download_avatar_container {
    margin-top: 1%;
    margin-bottom: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 2px #45FFA6 solid; 
    padding: 3%;
    border-radius: 10px;
}

.createTournament_download_avatar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.createTournament_download_avatar_title {
    font-size: 25px;
    margin-bottom: 3%;
}

.createTournament_download_avatar input {
    width: 50%;
}

.createTournament_avatar_container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5%;
}

.createTournament_avatar {
    position: relative;
    width: 298px;
    height: 167px;
    border-radius: 7%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createTournament_avatar img {
    width: 100%;
    height: 167px;
    border-radius: 7%;
    object-fit: cover
}

.createTournament_avatar_button {
    background-color: #45FFA6;
    color: rgb(53, 53, 53);
    font-weight: 500;
    padding: 8px 12px;
    border-radius: 50%;
    font-weight: 1000;
    position: absolute;
    bottom: -6%;
    right: -5%;
    border: none;
}

.createTournament_avatar_button_arrowIcon {
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    width: 1000;
}

.createTournament_download_avatar input {
    width: 100%;
    margin-bottom: 0;
}

.create_tourn_label {
    font-size: 16px;
}

.create_tourn_label.form-floating>input+label:after {
    background: #343434 !important;
}

.create_tourn_label.form-floating>input+label {
    color: rgb(156, 156, 156) !important;
}

.create_tourn_label.form-floating>textarea+label:after {
    background: #343434 !important;
}

.create_tourn_label.form-floating>textarea+label {
    color: rgb(156, 156, 156) !important;
}

.added_membersContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 50px;
} 

.create_tournament_form_control.additionalMembers {
    background-color: #343434;
    color: #fff;
    margin-bottom: 20px;
    border: none !important;
    border-radius: 10px;
    text-align: center;
}

.added_membersContainer .form-control {
    background-color: #343434;
    color: rgb(156, 156, 156);
    font-size: 12px;
} 

.added_membersContainer .option {
    text-align: center;
}

.saveStreamerButton {
    width: 100%;
    background-color: #45FFA6;
    color: #000;
    font-family: Nasalization Rg;
    border: 2px solid #45FFA6;
    border-radius: 7px;
    transition: all 500ms ease;
    font-size: 15px;
    padding: 3px;
    &:hover {
        background-color: #343434;
        color: #45FFA6;
    }
}

.popover {
    --bs-popover-bg: #212121;
    --bs-popover-header-bg: #212121;
}
.popover-body{
    color: rgb(219, 219, 219);
    text-align: center;
}

.rolesList_container {
    position: relative;
    display: flex;
    flex-direction: column;
    & :is(button) {
        border: 1px solid #45FFA6;
        background-color: #45FFA6;
        border-radius: 7px;
        padding: 5px 10px;
        font-size: 13px;
        color: #000;
        font-weight: bold;
        width: 170px;
    }
    .rolesList_arrrow {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 150ms linear;
    }
}