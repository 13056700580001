.teamPlayers_staff_mainContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    gap: 30px;
}

.staffContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.staffMembersContainer {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: stretch;
}

.staffMemberCard_imgContainer {
    width: 20%;
    & :is(img) {
        height: 55px;
        border-radius: 50%;
        border: 1px solid #45ffa6;
        width: 55px;
    }
}

.staffMemberCard_desc {
    width: 70%;
    text-align: center;
}

.staffTitle {
    letter-spacing: 1px;
}

.staffMemberCard {
    width: 300px;
    height: 80px;
    padding: 10px 15px;
    background-color: #3c3c3c;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
} 

.staffMemberCard_userName {
    letter-spacing: 1px;
    transition: color 500ms ease;
    cursor: pointer;
    color: #10D4FF;
}