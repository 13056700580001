.RoundRobinBracket_container {
    width: 100%;
    
    > .RoundRobinBracket_goup {
        backdrop-filter: blur(100px);
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 30px;
        border-radius: 20px;
        
        > .RoundRobinBracket_round {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: 1px solid #7a7a80;
            padding-bottom: 30px;
            margin-top: 30px;
            position: relative;
            
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
            
            > .RoundRobinBracket_matchContainer {
                width: 25%;
                min-width: 160px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:first-child {
                    justify-content: start;
                }
                &:last-child {
                    justify-content: end;
                }
            }
            
            > .RoundRobinBracket_roundTitle {
                font-size: 11px;
                position: absolute;
                top: -40px;
                left: 50%;
                transform: translateX(-50%);
                background-color: #201e1e;
                padding: 2px 5px;
                border: 1px solid #7a7a80;
                border-radius: 4px;
                letter-spacing: 1px;
                transition: color 400ms ease;
            }
        }
    }
    
    .groupsList {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px 0px;
        gap: 5%;
        
        .groupsListItem {
            width: 20%;
            padding: 5px;            
            background-color: transparent;
            border: 1px solid #45ffa6;
            color: #45ffa6;
            font-family: Nasalization Rg;
            font-weight: 600;
            border-radius: 4px;
            text-align: center;
            cursor: pointer;
        }
        .groupsListItem.active {
            width: 20%;
            padding: 5px;            
            background-color: #45ffa6;
            border: 1px solid #45ffa6;
            color: #222;
            font-family: Nasalization Rg;
            font-weight: 600;
            border-radius: 4px;
            text-align: center;
            cursor: pointer;
        }
    }
}