.choose_game_container {
    width: 100%;
    min-height: 90vh;
    padding-top: 11vh;
}

.choose_game_list {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 300px);
    grid-gap: 20px;
}
.choose_game_card.empty{
    opacity: 0.5;
}
.choose_game_card_img {
    height: 430px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.09), 
        0px 12px 13px 0px rgba(0, 0, 0, 0.17), 
        0px 4px 6px 0px rgba(0, 0, 0, 0.12), 
        0px -12px 30px 0px rgba(0, 0, 0, 0.12), 
        0px 54px 55px 0px rgba(0, 0, 0, 0.25);
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
}

.choose_game_card_img:hover { 
    transform: scale(1.035);
}