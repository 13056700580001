.tournament_timetable_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 300px;
    width: 80%;
    align-self: center;
    background-color: #222222;
    padding: 10px;
    border-radius: 10px;
    font-family: Proxima Nova Rg;
}
.tournament_timetable_container i {
    font-size: 18px;
    margin-top: 20px;
    color: rgb(128, 128, 128);
}
.tournament_timetable_fastcup{
    font-size: 16px;
    margin-bottom: 10px;
    padding: 10px;
}

.tournament_timetable_header {
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
    padding: 4px 10px;
}

.tournament-schedule-table {
    width: 100%;
    background-color: #2f2f2f;
    text-align: center;
    font-size: 18px;
    color: #bebebe;
}

.tournament-schedule-table td {
    padding: 7px 0px;
}

.tournament-schedule-table tr:nth-child(even) {
    background-color: #272727;
}

.tournament-schedule-table tr:nth-child(odd) {
    background-color: #2f2f2f;
}

.tournament-schedule-match a {
    text-decoration: none;
    color: #bebebe;
}

.tournament-schedule-match:hover {
    cursor: pointer;
    color: #45FFA6;
}
.table-team-vs{
    color:#45FFA6;
}
.table-team-left{
    text-align: right;
    cursor: pointer;
}
.table-team-right{
    text-align: left;
    cursor: pointer;
}
.table-team-left a,
.table-team-right a {
    text-decoration: none;
}

.table-team-left a:focus,
.table-team-right a:focus {
    color: #bebebe;
}

.table-team-link {
    color: #bebebe;
}

.table-team-link:hover {
    color: #10D4FF;
    cursor: pointer;
}

.table-team-link.selected {
    color: #10D4FF;
}
