.lastActivityStatistics_container {
    width: 100%;
    height: 250px;
    display: flex;
    font-family: Proxima Nova Rg;
    position: relative;
}

.lastActivityStatistics_firstBlock {
    width: 50%;
    display: flex;
}

.lastActivityStatistics_firstBlock_imgContainer {
    width: 45%;
    object-fit: cover;
    position: relative;
    
    .swapMapIcon_wrapper {
        .swapMapIcon {
            font-size: 25px;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
        }
        
        > div {
            padding: 5px 15px;
            border-radius: 7px;
            background-color: #343434;
            text-align: center;
            position: absolute;
            top: 25px;
            left: 0;
            
            > p {
                font-size: 15px;
                text-align: center;
                padding: 0;
                cursor: pointer;
                transition: color 500ms ease;
                text-transform: uppercase;
            }
            > p.active {
                color: #45FFA6;
            }
        }
    }
}

.lastActivityStatistics_firstBlock_img {
    width: 100%;
    height: 130px;
    border-radius: 5px;
}

.lastActivityStatistics_firstBlock_resultBlock {
    width: 100%;
    font-size: 18px;
    margin-top: 10px;
    display: flex;
}

.lastActivityStatistics_firstBlock_result {
    width: 50%;
    border: 1px solid #626262;
    text-align: center;
    padding: 5px;
    text-transform: uppercase;
    color: #fff;
}
.lastActivityStatistics_firstBlock_result.date {
    border-left: none;
}
.lastActivityStatistics_firstBlock_result.result {
    border-right: none;
    border-left: none;
}
.lastActivityStatistics_firstBlock_result.result.win {
    color: #45FFA6;
}
.lastActivityStatistics_firstBlock_result.result.loss {
    color: #C11328;
}

.lastActivityStatistics_table {
    width: 50%;
    border-right: 1px solid #626262;
    border-top: 1px solid #626262;
    padding: 5px;
    margin-left: 10px;
    & :is(table) {
        width: 100%;
    }
    & :is(thead) {
        width: 100%;
        border-bottom: 1px solid #626262;
    }
    & :is(tbody) {
        width: 100%;
    }
    & :is(tr) {
        width: 100%;
        font-size: 20px;
    }
}
.lastActivityStatistics_table_td {
    color: #45FFA6;
    padding: 5px;
    text-align: center;
}
.lastActivityStatistics_table_td.center {
    border-left: 1px solid #626262;
    border-right: 1px solid #626262;
}

.lastActivityStatistics_table_th {
    padding: 5px;
    text-align: center;
    font-weight: 300;
}
.lastActivityStatistics_table_th.center {
    border-left: 1px solid #626262;
    border-right: 1px solid #626262;
}

.lastActivityStatistics_table_matchOrTournamentTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid #626262;
    cursor: pointer;
    transition: color 500ms ease;
    &:hover {
        color: #10D4FF;
    }
}
.lastActivityStatistics_table_tournamemtTitle {
    text-align: center;
    font-size: 20px;
    width: 100%;
}
.lastActivityStatistics_table_matchTitleContainer {
    width: 100%;
    display: flex;
    align-items: center;
    & :is(span) {
        width: 10%;
        color: #45FFA6;
        font-size: 20px;
    }
}
.lastActivityStatistics_table_matchTitle {
    width: 45%;
    font-size: 17px;
}

/* second_block */


.lastActivityStatistics_secondBlock {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.lastActivityStatistics_secondBlock_title {
    text-align: start;
    color: #CECECE;
    font-size: 12px;
    margin-bottom: 10px;
}

.lastActivityStatistics_secondBlock_circles {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.lastActivityStatistics_secondBlock_circleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lastActivityStatistics_secondBlock_circle {
    width: 145px;
    height: 145px;
    padding: 15px;
    border-radius: 50%;
    border: 15px solid #45FFA6;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lastActivityStatistics_secondBlock_circle.kills {
    border-color: #45FFA6;
}
.lastActivityStatistics_secondBlock_circle.death {
    border-color: #C11328;
}
.lastActivityStatistics_secondBlock_circle.sapport {
    border-color: #10D4FF;
}

.lastActivityStatistics_secondBlock_circleTitle {
    color: #fff;
    margin-top: 10px;
    text-align: center;
    font-size: 25px;
}

.lastActivityStatistics_spinnerContainer {
    min-height: 220px;
    width: 100%;
    text-align: center;
    align-content: center;
}

.lastActivityStatistics_message {
    width: 100%;
    height: 100px;
    align-content: center;
}