.navbar {
    width: 100%;
    height: 10vh;
    padding: 1.5vh 5.83vw;
    font-family: "Tektur", serif;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: transform 0.6s ease-in-out;

    .navbar_content {
        width: 100%;
        height: 100%;
        background: rgb(50, 50, 50);
        border-radius: 0.7vw;
        padding: 0.5vw 1.5vw;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .navbar_contentLink {
            text-decoration: none;
            color: #A0A0A0;
            font-size: 0.7vw;
            cursor: pointer;
            transition: color 300ms ease;

            &:hover {
                color: #45FFA6;
            }
        }
        .navbar_contentLink.currentLink {
            color: #45FFA6;
        }

        .navbar_contentLinks {
            height: 100%;
            display: flex;
            align-items: center;
            gap: 3vw;

            .navbar_contentLogo {
                height: 100%;
                cursor: pointer;
            }

            .navbar_contentLink {
                text-decoration: none;
                color: #A0A0A0;
                font-size: 0.7vw;
                cursor: pointer;
                transition: color 300ms ease;

                &:hover {
                    color: #45FFA6;
                }
            }
            .navbar_contentLink.currentLink {
                color: #45FFA6;
            }
        }

        .navbar_contentButtons {
            height: 100%;
            display: flex;
            align-items: center;
            gap: 1vw;

            .navbar_contentButton {
                background: #45FFA6;
                color: #121212;
                border-radius: 0.7vw;
                border: 1px solid #45FFA6;
                height: 100%;
                padding: 0 1.5vw;
            }

            .navbar_contentButton.login {
                background: transparent;
                border-color: #fff;
                color: #fff;
            }
        }

        .navbar_contentUserWrapper {
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 3vw;

            .navbar_contentUser_notificationWrapper {
                height: 100%;
                display: flex;
                align-items: center;
                gap: 1.5vw;

                .navbar_bell_container {
                    position: relative;
                    z-index: 1000;

                    .navbar_bell {
                        font-size: 25px;
                        cursor: pointer;
                    }

                    .navbar_notif_dot {
                        background-color: rgb(202, 52, 52);
                        border-radius: 50%;
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 20%;
                    }

                    .notification_wrapper {
                        position: relative;
                        display: none;
                    }

                    .notification_wrapper::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 3px;
                        border-width: 10px;
                        border-style: solid;
                        border-color: transparent transparent rgb(235, 235, 235) transparent;
                        z-index: 1000;
                    }

                    .notification_wrapper.visible {
                        display: block;
                    }
                }

                .navbar_lobbies_container {

                    .navbar_lobbiesBtn {
                        cursor: pointer;
                    }

                    .notification_wrapper {
                        position: relative;
                        display: none;
                    }

                    .notification_wrapper::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 3px;
                        border-width: 10px;
                        border-style: solid;
                        border-color: transparent transparent rgb(235, 235, 235) transparent;
                        z-index: 1000;
                    }

                    .notification_wrapper.visible {
                        display: block;
                    }
                }
            }

            .navbar_contentUser {
                height: 100%;
                width: 40%;
                border-radius: 0.7vw;
                border: 0.06vw solid #45FFA6;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.3vw 1vw;

                .navbar_contentUserAvatarContainer {
                    height: 100%;
                    width: 25%;
                    align-content: center;

                    .navbar_contentUserAvatar {
                        height: 100%;
                        background: #121212;
                        border-radius: 50%;
                    }
                }

                .navbar_contentUserLoginContainer {
                    width: 50%;
                    text-align: center;

                    .navbar_contentUserLogin {
                        font-size: 0.9vw;
                        text-decoration: none;
                        color: #A0A0A0;
                        cursor: pointer;
                        transition: color 300ms ease;

                        &:hover {
                            color: #45FFA6;
                        }
                    }
                    .navbar_contentUserLogin.currentLink {
                        color: #45FFA6;
                    }
                }

                .navbar_contentUserDropdown {
                    height: 100%;
                    width: 25%;
                    text-align: end;
                    align-content: center;

                    .navbar_contentUserDropdownIcon {
                        font-size: 1.4vw;
                        color: #fff;
                        cursor: pointer;
                    }
                }
            }
        }

        .navbar_dropdown {
            width: 17%;
            background: rgb(50, 50, 50);
            border-radius: 0.7vw;
            border: 0.06vw solid #45FFA6;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 10vh;
            right: 7.33vw;
            z-index: 1000;

            .navbar_dropdownLink {
                height: 3vw;
                width: 100%;
                align-content: center;
                text-align: center;
                text-decoration: none;
                color: #fff;
                font-size: 0.8vw;
                cursor: pointer;
                transition: color 300ms ease;

                &:hover {
                    color: #45FFA6;
                }
            }
            .navbar_dropdownLink.disabled {
                color: rgb(102, 104, 103);
                cursor: default;
            }
            .navbar_dropdownLink.logOut {
                color: #FF0000FF;
            }
        }
    }
}

.navbar.hidden {
    transform: translateY(-100%); /* Скрывает Navbar */
}

.navbar.visible {
    transform: translateY(0); /* Показывает Navbar */
}