.SingleBracket_container {
    width: 100%;

    > .SingleBracket_group_wrapper {
        display: flex;
        flex-direction: column;
        backdrop-filter: blur(100px);
        padding: 50px;
        border-radius: 20px;

        > .SingleBracket_group {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 100px;
            position: relative;

            > .SingleBracket_round {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }
    }
}