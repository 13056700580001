.notFound_container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: MyFont Rg;
    pointer-events: none;
}

.lottie_container {
    margin-top: 220px;
    width: 580px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 50px;
    & :is(h3) {
        position: absolute;
        font-size: 300px;
        &:first-child {
            left: 0;
        }
        &:last-child {
            right: 0;
        }
    }
}

.lottie {
    z-index: 1;
}

.notFound_text {
    font-size: 40px;
    letter-spacing: 2px;
}