.neva_serversMap_container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-bottom: 11.66vw;
}

.neva_serversMap {
    position: relative;
    z-index: 1;
    padding: 4vw 0;
    text-align: center;

    .neva_serverMap_content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 0;
        padding: 0 5.83vw;
        z-index: 10;

        .neva_serverMap_contentTitle {
            font-size: 3vw;
            line-height: 1;
        }

        .neva_serverMap_contentDesc {
            text-align: start;
            color: #A0A0A0;
            font-size: 0.9vw;
            line-height: 1.5;
        }
    }
}
.neva_serversMap::before,
.neva_serversMap::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 15%;
    background: linear-gradient(to bottom, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 0) 100%);
    z-index: 10;
    pointer-events: none;
}
.neva_serversMap::before {
    top: 0;
    transform: translateY(-1px);
}
.neva_serversMap::after {
    bottom: 0;
    background: linear-gradient(to top, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 0) 100%);
    transform: translateY(1px);
}

.neva_serverMap_bg {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    animation: rotate-spin 15s linear infinite;

    .neva_serverMap_circle {
        z-index: 1;
        width: 30vw;
        height: 30vw;
        border-radius: 50%;
        filter: blur(150px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-10%, -70%);
    }
    .neva_serverMap_circle.one {
        background: rgba(61, 255, 229, 0.8);
        transform: translate(-100%, -70%);
    }
    .neva_serverMap_circle.two {
        background: rgba(77, 40, 255, 0.8);
        transform: translate(-20%, -100%);
    }
    .neva_serverMap_circle.free {
        background: rgba(78, 155, 255, 0.8);
        transform: translate(-30%, -10%);
    }
}

.full_map {
    width: 80%;
    height: auto;
    z-index: 2;

    .server {
        fill: #45ffa6;
        transition: r 0.3s ease-in-out, transform 0.3s ease-in-out;
        stroke-width: 20;
        stroke: transparent;

        circle {
            transition: r 0.3s ease-in-out, transform 0.3s ease-in-out;
        }
    }
    .server.disabled {
        fill: yellow;
    }
}

.neva_serversMap_tooltip {
    position: relative;
    padding: 8px 12px;
    background-color: #7F7F7F;
    z-index: 9999;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    font-family: "Jura", serif;
    font-size: 0.8vw;
    border-radius: 0.7vw;
    animation: fade-in 0.3s ease-in-out forwards;

    .neva_serversMap_serverStatusIcon {
        height: 100%;
        fill: red;
    }
    .neva_serversMap_serverStatusIcon.active {
        fill: #45FFA6;
    }

    &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 1.3vw;
        transform: translateX(-50%);
        border-width: 6px;
        border-style: solid;
        border-color: #7F7F7F transparent transparent transparent;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: scale(0.95) translateY(10%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@keyframes rotate-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}