.current_tournaments_maps{
    width: auto;
    background-color: #1b1b1bdf;
    border-radius: 10px;
    -webkit-box-shadow: 2px 3px 25px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 3px 25px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 3px 25px -5px rgba(0, 0, 0, 0.75);
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.modal-backdrop{
    background-color: rgba(0, 0, 0, 0.438);
}
.cs2_modal .modal-content{
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    border: grey;
    color: black;
    font-weight: 900;
}
.сs2_mapselection_maps{
    color: white;
    font-family: Nasalization Rg;
    margin: 25px 0;
}
.cs2_map_container{
    position: relative;
    background-blend-mode: darken; 
    width: 100%;
}

.cs2_delete_icon{
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    font-size: 35px;
}
.cs2_pen_icon{
    position: absolute;
    color: rgb(255, 255, 255);
    top: 30%;
    right: 1%;
    font-size: 20px;
}
.competitive_overlay{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    font-weight: bold;
    color: rgba(44, 217, 21, 0.9);
    background-color: rgba(0, 0, 0, 0.995);
    padding: 10px;
    border-radius: 5px;
}