.customSpinner_container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    pointer-events: none;
}

.customSpinner {
    width: 100px;
    height: 100px;
}