.cs2UserProfile_tabs {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.cs2UserProfile_tab {
    padding: 5px 10px;
    background-color: transparent;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #45FFA6;
}
.cs2UserProfile_tab.active {
    background-color: #45FFA6;
    color: #222;
}

.cs2UserProfile_second_container {
    width: 100%;
}

.cs2UserProfile_second_lowerBlock {
    margin-top: 50px;
}

.cs2UserProfile_third_container {
    display: flex;
    flex-direction: column;
    min-height: 160px;
    margin-top: 50px;
}

.cs2UserProfile_stat_second_levels {
    display: flex;
    text-align: left;
    margin: 30px 0;
}

.cs2UserProfile_stat_second_level1 {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cs2UserProfile_detailedstat_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 200px;
}