.alm-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-family: Nasalization Rg;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 13px;
}

    .alm-table th, .alm-table td {
        padding: 11px;
        border: 1px solid #7a7a80;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .alm-table th {
        background-color: #7a7a80;
        font-weight: bold;
    }

    .alm-table td {
        background-color: #222;
    }

.alm-game-image {
    height: 25px;
    width: 25px;
    border-radius: 50%;
}
