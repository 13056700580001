*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

* {
  margin: 0;
  padding: 0;
}
*::selection {
  color: #45FFA6;
  background: transparent;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.app_inner_container {
  background-color: #252525;
  background-image: url(./assets/img/background_radiance.png);
  background-repeat: no-repeat;
  -moz-background-size: 100%;
  -webkit-background-size: 100%; 
  -o-background-size: 100%; 
  background-size: 100%; 
  min-height: 100vh;
}

.app_content_container{
  min-height: 100vh;
} 

.lobby_container {
  position: fixed;
  right: 0; 
  top: 13%; 
  transition: right 450ms ease;
  margin-right: 1%;
  margin-left: 1%;
}

.modal_button {
  color: #252525;
  font-weight: 600;
  background-color: #45FFA6;
  border: 1px solid #45FFA6;
  border-radius: 10px;
  padding: 13px 30px;
  margin-bottom: 4%;
  margin-top: 4%;
  cursor: pointer;
  transition: all 350ms ease-in-out;
  &:hover {
      color: #45FFA6;
      border-color: #45FFA6;
      background-color: transparent;
  }
}

.modal_button:active,
.modal_button:focus,
.modal_button.focus {
  box-shadow: none !important;
  background-color: transparent !important;
  border-color: #45FFA6 !important;
  color: #45FFA6 !important;
}