.news_modal_container {
    --bs-modal-width: 65%;
    margin-top: 2%;
    cursor: pointer;
    display: flex;
}

.news_modal_body {
    font-family: Nasalization Rg;
    color: #fff;
    box-shadow: 
        0px -3px 5px 0px rgba(0, 0, 0, 0.09), 
        0px 12px 13px 0px rgba(0, 0, 0, 0.17), 
        0px 4px 6px 0px rgba(0, 0, 0, 0.12), 
        0px -12px 30px 0px rgba(0, 0, 0, 0.12), 
        0px 54px 55px 0px rgba(0, 0, 0, 0.25);
    height: 85vh;
    background-image: url('../../assets/img/modal_background_radiance.png');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    padding: 5%;
    cursor: default;
    border-radius: 30px;
}

.modal_window_title {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height);
}

.news_modal_description {
    width: 85%;
    height: auto;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: justify;
}

.news_modal_imgSrc {
    margin-top: 5%;
    width: 85%;
    height: auto;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}