.registration_container {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: Nasalization Rg;
}
.confirm-mail-letters{
  font-family: Nasalization Rg;
  margin-top: 15px;
  font-size: 18px;
  text-align: center;
}
.confirm_mail_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;ustify-content: flex-start;
  font-size: 33px;
  font-family: Nasalization Rg;
}

.registration_choose {
  font-size: 40px;
  margin: 100px 0 80px 0;
}

.registration_boxes_container {
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.registration_box {
  border: 1px solid #45FFA6;
  box-shadow: 0 0 5px #45FFA6;
  border-radius: 10px;
  padding: 50px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  transition: transform 0.3s ease-in-out;
}
.registration_box.disabled {
  opacity: 0.5; 
  cursor: not-allowed;
}

.registration_box:hover {
  transform: scale(1.1); 
  background-color: #383838;
}


/*---------- REGISTRATION FORM ----------*/


.registration_form_container {
  width: 100%;
  height: auto;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.registration_header {
  font-family: Nasalization Rg;
  font-size: 36px;
  text-align: center;
  margin: 40px 0;
}

.registration_form {
  width:50%;
  background-color: #3e3e3e;
  padding: 20px;
  border-radius: 10px;
}
.reg_floatlabel{
  color: rgb(37, 37, 37);
}
.registration_description {
  height: 120px !important;
}
.reg_datepicker{
  margin-bottom: 20px;
}
.reg_datepicker .rs-input{
  background-color: #fff !important;
}
.reg_datepicker .rs-input-group.rs-input-group-inside {
  background-color: white!important;
}
.reg_datepicker .rs-input-group{
  border: 2px solid #45FFA6 !important;
}
.reg_datepicker.rs-calendar-body{
  color: rgb(60, 60, 60) !important;
}
.select_reg.form-select {
  border: 2px solid #45FFA6 !important;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content  {
  background-color: #45ffa5 !important;
}
.reg_datepicker.rs-calendar-month-dropdown-cell-content:hover{
  background-color: #45ffa543  !important;
  color: rgb(54, 54, 54) !important;
}
.reg_datepicker.rs-calendar-month-dropdown-year-active{
  color:#383838 !important;
}
.reg_datepicker.rs-calendar-header-title-date {
  color:#383838 !important;
}
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content{
  background-color: #45ffa5 !important;
}
.reg_datepicker.rs-calendar-table-cell:hover{
  background-color: #45ffa543 !important;
  border-radius: 7px;
}
.reg_datepicker.rs-calendar-table-cell-content:hover{
  color: rgb(55, 55, 55) !important;
}
.rs-btn-primary{
  background-color: #45ffa5!important;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content{
  box-shadow: inset 0 0 0 1px #45FFA6 !important;
}
.sign_in_button_container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.download_avatar_container {
  margin-top: 7%;
  margin-bottom: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 2px #45FFA6 solid;
  padding: 3%;
  border-radius: 10px;
}

.download_avatar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download_avatar_title {
  font-size: 25px;
}

.download_avatar input {
  width: 50%;
}

.avatar_container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 5%;
}

.avatar {
  position: relative; 
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar img {
  width: 100%;
  height: 100%; 
  border-radius: 50%; 
  object-fit: cover;
}

.avatar_button {
  background-color: #45FFA6;
  color: rgb(53, 53, 53);
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 50%;
  font-weight: 1000;
  position: absolute; 
  bottom: -5%; 
  right: 10%;
  border: none;
}

.avatar_button_arrowIcon {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  width: 1000;
}

.form-control[type=file]:active {
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
  box-shadow: none !important;;
}

.form-control[type=file] {
  border: 2px #45FFA6 solid;
}

@media (max-width: 1100px) {
  .registration_container {
    margin-top: 100px;
    justify-content: flex-start;
  }
  
  .registration_choose {
    font-size: 20px;
    margin: 20px 0 20px 0;
  }
  
  .registration_form {
    width: 90%;
    background-color: #3e3e3e;
    padding: 20px;
    border-radius: 10px;
  }
  
  .registration_boxes_container {
    gap: 30px;
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: space-between;
    align-items: center;
  }
  
  .download_avatar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .download_avatar_title {
    font-size: 20px;
  }
  
  .form-control[type=file]:not(:disabled):not([readonly]) {
    width: 100%;
    margin-top: 5%;
  }
  
  .registration_header {
    font-family: Nasalization Rg;
    font-size: 20px;
    text-align: center;
    margin: 40px 0;
  } 
}