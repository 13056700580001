.SteppedLineTo_baseline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.SteppedLineTo_baseline .SteppedLineTo_linePath {
    stroke-dasharray: none;
    stroke-dashoffset: 0;
}

.SteppedLineTo_animateLine {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.SteppedLineTo_animateLine .SteppedLineTo_linePath {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: drawLine 1300ms forwards ease-in;
}

@keyframes drawLine {
    to {
        stroke-dashoffset: 0;
    }
}

.SteppedLineTo_flashLine {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.SteppedLineTo_flashLine .SteppedLineTo_linePath {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: flashLine 1500ms infinite;
}

@keyframes flashLine {
    0% {
        opacity: 0;
        stroke-dashoffset: 0;
    }
    50% {
        opacity: 1;
        stroke-dashoffset: 0;
    }
    100% {
        opacity: 0;
        stroke-dashoffset: 0;
    }
}