.StatisticsIsNotFound_container {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    
    .StatisticsIsNotFound_blur {
        filter: blur(1.5px);
        transition: filter 0.3s ease;
        pointer-events: none;
        user-select: none;
        width: 100%;
        height: 100%;
        text-align: center;
        align-content: center;
    }
    
    .StatisticsIsNotFound_title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 15px;
        z-index: 100;
        padding: 10px;
        color: white;
        text-align: center;
        font-size: Nasalization Rg;
        border-radius: 7px;
        background: rgba(0, 0, 0, 0.9);
    }
}