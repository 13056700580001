.team_about_container {
    display: flex; 
    flex-direction: column;
    width: 100%;
    height: auto; 
    /*transform: skew(-10deg);*/ 
}

.team_about_inner_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}
.team_about_inner_container_item{
    width: 45%;
}
.team_about_player_title {
    margin: 0;
    padding: 0;
    font-size: 25px;
    margin-bottom: 10px;
    letter-spacing: 2px;
    color: #45FFA6;
}

.team_about_desc, .team_about_partners {
    /*transform: skew(10deg);*/
    text-align: justify;
    font-size: 18px;
    margin: 10px 0;
    height: auto;
}

.team_about_desc {
    text-align: justify;
    text-justify: inter-word;
    overflow: hidden;
    font-family: Nasalization Rg
}
.team_about_partners.date {
    font-family: Nasalization Rg
}