.results_container {
    width: 100%;
    backdrop-filter: blur(150px);
    box-shadow: 1px 6px 15px 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    color: rgb(184, 184, 184);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Nasalization Rg;
    font-size: 16px;
    margin: 15px 0;
}

.results_container h4 {
    text-transform: uppercase;
    text-align: center;
}

.results_container_tables {
    width: 100%;
}

.results_container_tables h3 {
    width: 100%;
    text-align: center;
}

.results_robin_table {
    width: 100%;
    margin-top: 10px;
}

.results_robin_table_row {
    text-align: center;
    border-radius: 5px;
    background: linear-gradient(119deg, rgba(27, 31, 42, 1) 0%, rgba(47, 56, 83, 1) 58%, rgba(66, 80, 113, 0.9304096638655462) 100%);
    padding: 0.5% 10%;
    border: 1px rgb(145, 145, 145) solid;
    width: 300px;
}


.result_table_row.header {
    height: 34px;
    font-size: 18px;
}

.groupstage_tables {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 30px 0;
}

.results_points {
    display: flex;
    flex-direction: column;
    width: 300px;
    border: 1px solid #ccc;
    background: linear-gradient(119deg, rgba(27, 31, 42, 1) 0%, rgba(47, 56, 83, 1) 58%, rgba(66, 80, 113, 0.9304096638655462) 100%);
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

.result_table_header {
    background-color: #f0f0f0;
    font-weight: bold;
}

.result_table_row {
    display: flex;
    flex-direction: row;
}

.result_table_cell {
    flex: 1;
    padding: 0.5% 10%;
    border-bottom: 1px solid #ccc;
    text-align: center;
}

.result_table_cell.header {
    font-weight: 900;
    border: none;
}


.result_table_row:last-child .result_table_cell {
    border-bottom: none;
}

.result_table_row:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.result_table_row:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.results_points_playoff {
    display: flex;
    flex-direction: column;
    width: 300px;
    border: 1px solid #ccc;
    background: linear-gradient(119deg, rgba(27, 31, 42, 1) 0%, rgba(47, 56, 83, 1) 58%, rgba(66, 80, 113, 0.9304096638655462) 100%);
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

.results_playoff_table_row {
    text-align: center;
    border-radius: 5px;
    background: linear-gradient(119deg, rgba(27, 31, 42, 1) 0%, rgba(47, 56, 83, 1) 58%, rgba(66, 80, 113, 0.9304096638655462) 100%);
    padding: 0.5% 10%;
    border: 1px rgb(145, 145, 145) solid;
    width: 300px;
}