.user_stat_table {
    width: 100%;
    font-weight: 300;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0 0.5em;
    margin-top: -20px;
}

.user-stat-table tr {
    position: relative;
}

.user_stat_table_header {
    font-weight: 100;
    font-size: 12px;
    padding: 0 5px;
}

.user_stat_table_row {
    background-color: #333333;
}

.user_stat_table_td {
    border-right: 1px solid #252525;
    padding: 5px 5px;
}

.user_stat_table_td:first-child,
.user-stat-table-header:first-child {
    border-radius: 8px 0 0 8px;
}

.user_stat_table_td:last-child,
.user-stat-table-header:last-child {
    border-radius: 0 8px 8px 0;
}

.user_stat_table_td.teams_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #333333;
    padding: 5px 30px;
}

.user_stat_table_td.teams_container.pStat {
    transition: all 500ms ease;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        border: 1px solid #45FFA6;
    }
}

.user_stat_table_td_row_team_mainContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user_stat_table_td_row_team_mainContainer > span {
    width: 10%;
}

.user_stat_table_td_row_team_container {
    display: flex;
    align-items: center;
    padding: 0px 5px;
    transition: all 500ms ease;
    cursor: pointer;
    z-index: 0;
    width: 45%;
    &:hover {
        color: #45FFA6;
    }
    &.second {
        justify-content: end;
    }
}

.user_stat_table_td_row_team {
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
.user_stat_table_td_row_team.player{
    padding-left: 10px;
    font-weight: 900;
    &:hover {
        cursor: pointer;
        color: #45FFA6;
    }
}
.user_stat_table_td.teams {
    width: 40%;
    padding: 0px;
}

.user_stat_table_td_img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
    margin: 0px 5px;
}

.user_stat_table_spinnerContainer {
    min-height: 220px;
    width: 100%;
    text-align: center;
    align-content: center;
}

.user_stat_table_message {
    width: 100%;
    height: 100px;
    align-content: center;
}