.tournaments_manual_container {
    padding: 20px;
    width: 100%;
    background-color: #242424b6;
    border-radius: 10px;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
}

.tournaments_manual_container input {
    color: black;
}

.tournaments_manual_container .btn {
    margin: 2px;
}

.tournaments_manual_container_layer1 {
    display: flex;
    justify-content: space-between;
}

.tournaments_manual_info {
    display: flex;
    flex-direction: column;
}

.tournaments_manual_bracket {
    margin: 25px 0;
}

.tournaments_manage_tab_container i {
    cursor: pointer;
    color: #45FFA6;
    padding-left: 10px;
}

.tournaments_manual_unit {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.tournaments_manual_unit_table table {
    font-size: 14px;
}

.tournaments_manual_buttons {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.tournaments_manual_unit h6 {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 20px;
}

.tournaments_manual_container_layer2 {
    width: 100%;
    display: flex;
}

.current_tournaments_table.manual {
    background-color: #ffffffc5;
    width: 100%;
    margin-bottom: 20px;
    font-weight: normal;
    color: rgb(29, 28, 28);
    border-radius: 0;
}

.tournaments_manual_addteams {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 40%;
}

.search-results {
    margin-top: 7px;
}

.admin_servers_manage {
    background-color: #3e48819d;
    padding: 10px;
    display: flex;
    min-height: 300px;
    margin-bottom: 30px;
}

.server_zone_container {
    width: 65%;
    margin-right: 30px;
}
.server_zone_search{
    display: flex;
    align-items: center;
    width: 100%;
}
.serverzone_option{
    width: 500px;
}
.server_zones_units{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.servers_info_container {
    text-align: left;
    width: 45%;
    background-color: #e6e6e642;
    border-radius: 8px;
    font-size: 14px;
    padding: 5px;
    margin-bottom: 5px;
}