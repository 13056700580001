.search_team_container {
    background-color: #2c2f33;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
}

.search_team_top {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}
.rounded-pill.form-control:active,
.rounded-pill.form-control:focus,
.rounded-pill.form-control::placeholder,
.search_team_bar {
    width: 100%;
    color: #252525 !important;
}
.search_team_topButton {
    width: 10%;
}

.submit_team_button {
    background-color: #45ffa5;
    border: none;
    padding: 10px 20px;
    color: #23272a;
    border-radius: 20px;
    cursor: pointer;
    transition: all 300ms ease;
}

.teams_selecor_block {
    width: 50%;
    margin-top: 10px;
}

.searched_players_nickname {
    display: flex;
    align-items: center;
    background-color: #23272a;
    padding: 10px;
    border-radius: 8px;
    position: relative;
}

.searched_players_nickname_logo {
    width: 50px;
    height: 50px;
    background-color: #343434;
    border-radius: 50%;
    margin-right: 10px;
}

.searched_players_nickname_city {
    font-size: 0.8em;
    color: #bbb;
}

.searched_players_nickname_plus {
    color: #43b581;
    cursor: pointer;
}

.create_last_form_control {
    color: #ADADAD;
    margin-bottom: 20px;
    border: none !important;
    border-radius: 10px;
    min-height: 600px;
}

.teammates_block {
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    width: 700px;
    flex-wrap: wrap;
    align-items: flex-start;
}

.teammates_block_header {
    font-family: Nasalization Rg;
    color: #10D4FF;
    width: 100%;
    font-size: 22px;
    padding: 10px 0px 30px 10px;
}

.teammates__teamcreate_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.teammates__teamcreate_unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 200px;
}

.teammates__teamcreate_avatar {
    background-color: #343434;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}
.teammates__teamcreate_name {
    margin-bottom: 10px;
    text-decoration: none;
    color: #fff;
}
.teammates__teamcreate_name:focus {
    text-decoration: none;
}
.teammates__teamcreate_name:hover{
    cursor: pointer;
    color:#10D4FF;
    text-decoration: none;
}
.team_member_unit_name_x {
    font-size: 30px;
    cursor: pointer;
    color: white;
    position:absolute;
    top: 0;
    right: 0;
    transition: color 300ms ease;
}

.team_member_unit_name_x:hover {
    color: rgba(202, 22, 22, 0.872);
}

.create_tournament_form_control.rs-calendar-month-dropdown-year-active{
    color: #45ffa5 !important;
}
.create_tournament_form_control.rs-calendar-header-title-date{
    color: #45ffa5 !important;
}

.create_team_form_control {
    background-color: #343434;
    color: #fff;
    margin-bottom: 20px;
    border: none !important;
    border-radius: 10px;
}
.create_team_form_control.option {
    color: white;
    width: 100%;
}

.create_team_form_control option {
    text-align: center;
}

.create_team_form_control:focus {
    background-color: #343434;
    box-shadow: none;
}

.create_tournament_form_checkboxes {
    background-color: #343434;
    color: #fff;
    border: none !important;
    border-radius: 10px;
    text-align: start;
    font-size: 13px;
    width: 170px;
    padding: 10px 15px;
    position: absolute;
    top: 35px;
    z-index: 1;
}

.create_tournament_form_checkboxes .form-check {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-height: 1.5rem;
    padding: 0;
    margin-bottom: 0;
    margin-top: 7px;
    gap: 10px;
    color: #bebebe;
    &:first-child {
        margin-top: 0px;
    }
}

.create_tournament_form_checkboxes .form-check .form-check-input[type=checkbox] {
    color: #252525;
    border-radius: 0.25em;
    border: 1px solid #45FFA6;
    margin-right: 0;
    margin-left: 0;
    box-shadow: none;
    cursor: pointer;
    background-color: transparent;
}

.infoText {
    text-align: center;
}

.create_tournament_form .alert-danger {
    text-align: center;
}

.infoDescRole {
    text-align: start;
}