.lobby_unit {
    border-bottom: 1px solid #bebebe;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
        font-size: 0.8vw;
        cursor: default;
        letter-spacing: 0.5px;
    }
    > button {
        border: none;
        background-color: transparent;
    }
}
.notification_units_container.lobby{
    text-align: center;
}
.notification_center hr {
    margin: 0;
    border-top: 1px solid grey;
}
