.chosen_game_container {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: Nasalization Rg;
    padding-top: 11vh;
}

.chosen_game_first_container {
    display: flex;
}


.chosen_game_second_container {
    width: 100%;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
}

.chosen_game_slogan1 {
    font-size: 37px;
    font-weight: 500;
}

.chosen_game_slogan2 {
    font-size: 30px;
    font-weight: 500;
    color: #757474;
}
.chosen_game_third_maincontainer{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}
.tournaments_with_pagination_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.chosen_game_third_container {
    display: grid;
    margin: 50px 0px 40px 0px;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    min-height: 240px;
}

.chosen_game_socials_button_container.lists{
    margin-top: 40px;
}

@media (max-width: 980px) {
    .chosen_game_third_container {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        
    }
}

@media (max-width: 1480px) {
    .chosen_game_third_container {
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
    }
    .tournament_cell_container{
        min-width: 580px;
    }
}

.pagination_container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.active>.page-link, .page-link.active {
    background-color: #282828;
    border-color: transparent;
}
.page-link,
.page-link:focus,
.page-link:active {
    color: #45FFA6;
    background-color: #343434;
    border-color: transparent;
    text-decoration: none !important;
    cursor: pointer;
    &:hover{
        color: #45FFA6;
        background-color: #282828;
        border-color: transparent; 
    }
}