.DoubleBracket_container {
    width: 100%;
    position: relative;

    .DoubleBracket_groups {
        backdrop-filter: blur(100px);
        width: 100%;
        padding: 50px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 50px;
        -webkit-box-shadow: 5px 3px 20px 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 5px 3px 20px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 5px 3px 20px 10px rgba(0, 0, 0, 0.1);
        overflow: auto;
        position: relative;
    }

    .DoubleBracket_group_wrapper {
        > .DoubleBracket_group_roundsTitles {
            display: flex;
            gap: 100px;
            margin: 30px 0 15px 0;


            > .DoubleBracket_group_roundsTitle {
                width: 140px;
                min-width: 140px;
                text-align: center;
                font-family: "Nasalization Rg";
                font-size: 15px;
                background-color: #201e1e;
                border: 1px solid #7a7a80;
                padding: 10px 5px;
                border-radius: 3px;
            }
        }

        > .DoubleBracket_groupTitle {
            font-size: 25px;
            font-weight: normal;
            font-family: "Nasalization Rg";
        }

        > .DoubleBracket_group {
            width: 100%;
            display: flex;
            border-radius: 20px;
            align-items: center;
            gap: 100px;

            .DoubleBracket_round {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }
    }
}

.DoubleBracket_editButtons {
    > button {
        width: 13%;
        font: 13px "Nasalization Rg";
        color: #201e1e;
        background-color: #45ffa6;
        border: none;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
            background-color: #35e18e;
        }
    }
    > button:nth-child(2) {
        margin-left: 15px;
    }
}

.DoubleBracket_groups_loaderContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}