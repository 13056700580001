.modal_container {
    --bs-modal-width: 30%;
    margin-top: 5%;
    cursor: pointer;
}

.modal_body {
    font-family: Nasalization Rg; 
    color: #fff;
    background-image: url('../../../assets/img/modal_background_radiance.png');
    background-size: cover; 
    background-position: center; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
    border-radius: 30px;
}

.modal-content {
    border-radius: 40px;
}

.modal_title {
    font-size: 30px;
    margin-top: 6%;
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height);
}

.regButtonTimer {
    font-size: 13px;
    margin-bottom: 5px;
}


/*--------------------- FORM ---------------------*/


.modal_form {
    width: 90%;
    margin-top: 2%;
    padding: 1% 1%;
}

.form-control {
    margin-bottom: 5%;
    border: 2px solid #45FFA6;
}


.form-check-input:checked {
    background-color: #45FFA6;
    border-color: none;
}

.form-control:focus {
    box-shadow: none; 
    border-color: #45FFA6; 
}

.form-control.is-valid, .was-validated .form-control:valid {
    border-color: #18cf7a;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}


/*--------------------- BUTTON ---------------------*/


.modal_button {
    color: #252525;
    font-weight: 600;
    background-color: #45FFA6;
    border: 1px solid #45FFA6;
    border-radius: 10px;
    padding: 13px 30px;
    margin-bottom: 4%;
    margin-top: 4%;
    cursor: pointer;
    transition: all 350ms ease-in-out;
    &:hover {
        color: #45FFA6;
        border-color: #45FFA6;
        background-color: transparent;
    }
}

.modal_button:active,
.modal_button:focus,
.modal_button.focus {
    box-shadow: none !important;
    background-color: transparent !important;
    border-color: #45FFA6 !important;
    color: #45FFA6 !important;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    color: #ffffff;
    pointer-events: none;
    background-color: #45FFA6;
    border-color: #45FFA6;
    opacity: var(--bs-btn-disabled-opacity);
}