.chosen_tournament_team {
    width: 48%;
    margin: 10px;
    padding: 10px;
    background: linear-gradient(
        180deg, 
        #272727 0%, 
        #1E1E1E 100%
    );
    border-radius: 30px;
    min-height: 275px;
}

.chosen_tournament_team_layer1 {
    display: flex;
    justify-content: space-between;
}

.chosen_tournament_team_layer1_part {
    width: 48%;
    display: flex;
    text-align: center;
    margin-left: 18px;
}

.chosen_tournament_team_pic {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.chosen_tournament_team_infotext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 15px;
}

.chosen_tournament_team_infotext a {
    color: #fff;
    text-decoration: none;
}

.chosen_tournament_team_naming {
    font-size: 20px;
    font-weight: 700;
    width: 155px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 500ms ease;
    &:hover {
        color: #10D4FE;
    }
} 

.chosen_tournament_team_naming.playerTeam {
    color: #10D4FE;
}

.chosen_tournament_team_game {
    font-size: 18px;
    font-weight: 700;
    color: #737373;
    cursor: default;
}

.chosen-tournament-teams-tab-container {
    margin-top: 90px;
    display: flex;
    width: 100%;
}

.chosen_tournament_team_line {
    border-radius: 5px;
    background-color: #45FFA6;
    width: 3px;
    height: 42px;
}

.chosen_tournament_team_layer2 {
    display: flex;
    margin: 30px 0 10px 0;
}

.chosen_tournament_team_member {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.chosen_tournament_team_member_pic {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    object-fit: cover;
}

.chosen_tournament_team_member_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chosen_tournament_team_member_name {
    font-size: 14px;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover {
        color: #45FFA6;
    }
}

.chosen_tournament_team_member_role {
    font-size: 14px;
    font-weight: 400;
    color: #737373;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}