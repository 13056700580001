.modal-content {
    border-radius: 40px;
    background-color: transparent;
    border: transparent;
}

.modal-backdrop {
    --bs-backdrop-opacity: 0.25;
}


/*--------------------- MY ALERT ---------------------*/


.myAlert_container {
    cursor: pointer;
}

.myAlert_modal_body {
    cursor: default;
    margin-top: 75%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 3%;
    background-image: url(../../assets/img/modal_background_radiance.png);
}

.myAlert_text {
    font-family: MyFont Rg;
    font-size: 120%;
    letter-spacing: 1.5px;
    color: #fff;
    margin-bottom: 5%;
    text-align: center;
    font-weight: normal;
}

.myAlert {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myAlert_button {
    color: #252525;
    margin-right: 5%;
    font-family: Nasalization Rg;
    background-color: #45FFA6;
    border: 1px solid #45FFA6;
    border-radius: 10px;
    padding: 5px 18px;
    margin-bottom: 1%;
    margin-top: 1%;
    cursor: pointer;
    transition: all 350ms ease-in-out;
    &:hover {
        color: #45FFA6;
        border-color: #45FFA6;
        background-color: transparent;
    }
}

.myAlert_button:last-child {
    margin-right: 0;
}

.myAlert_button:active,
.myAlert_button:focus,
.myAlert_button.focus {
    box-shadow: none !important;
    background-color: transparent !important;
    border-color: #45FFA6 !important;
    color: #45FFA6 !important;
}