.news_item_container {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    border-radius: 30px;
    border: 1.8px solid transparent;
    box-shadow: 
        0px -3px 5px 0px rgba(0, 0, 0, 0.09),
        0px 12px 13px 0px rgba(0, 0, 0, 0.17), 
        0px 4px 6px 0px rgba(0, 0, 0, 0.12), 
        0px -12px 30px 0px rgba(0, 0, 0, 0.12), 
        0px 54px 55px 0px rgba(0, 0, 0, 0.25);
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat; 
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    transition: border 500ms ease;
    &:hover {
        border: 1.8px solid #45FFA6;
    }
}

.news_item_container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.09); 
    border-radius: 30px;
    z-index: 1;
}

.news_item_title {
    font-size: 25px;
    font-family: Nasalization Rg;
    
    position: relative; 
    z-index: 2;
    color: #FFF; 
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
    
    margin-left: 4%;
}