.tournament_cell_container {
    background-size: cover;
    height: auto;
    width: auto;
    border-radius: 15px;
    padding: 5px;
    cursor: pointer;
    max-width: 440px;
    position: relative;
    min-height: 238px;
}

.tournament_cell_container::before {
    content: '';
    position: absolute;
    border-radius: 15px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(28, 28, 28, 0.905);
    z-index: 1;
}

.tournament_cell_container>* {
    position: relative;
    z-index: 2;
}

.tournament_cell_first_layer {
    display: flex;
    padding-top: 10px;
    justify-content: space-between;
}

.tournament_cell_info_conatiner {
    width: 50%;
    display: flex;
}

.tournament_cell_img_container {
    padding: 0 5px;
}

.tournament_cell_img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    opacity: 0.8;
}

.tournament_cell_name {
    font-size: 17px;
    font-weight: 500;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: ease-in-out 0.3s;
}

.tournament_cell_name:hover {
    color: #10D4FE;
}

.tournament_cell_date {
    font-size: 13px;
    color: #959595;
    font-weight: 400;
}

.tournament_cell_time_conatiner {
    width: 50%;
    display: flex;
    margin-right: 4px;
    margin-top: 2px;
    flex-direction: column;
    align-items: flex-end;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    border-right: 4px solid #45FFA6;
    padding-right: 5px;
    height: 60%;
    min-height: 37px;
}

.tournament_cell_time_left {
    display: flex;
    justify-content: flex-end;
    color: #40eb98;
    white-space: nowrap;
}


.tournament_cell_horizontal {
    margin: 20px 7px;
    border: 1px solid #ffffff;
}

.tournament_cell_second_layer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

.tournament_versus_part2 {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    background: linear-gradient(180deg, #10D4FE 0%, #00FE88 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.tournament_versus_part_text_container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.tournament_versus_part_team1 {
    font-size: 10px;
    font-weight: 400;
    color: #CACACA;
}

.tournament_versus_part_team1_name {
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
}

.tournament_versus_img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover
}

.tournament_status_indicator_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.tournament_status_indicator {
    font-size: 10px;
    font-weight: 600;
    background-color: #40eb98;
    border-radius: 8px;
    padding: 1px 5px;
    color: #7c7c7c;
    text-transform: uppercase;
}
.tournament_cell_third_layer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.tournament_cell_third_layer_part1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}
