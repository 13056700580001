.match_header {
    font-size: 30px;
    font-weight: 900;
    font-family: Proxima Nova Rg;
    color: rgb(208, 208, 208);
    transition: all 0.6s ease;
    width: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 40px;
    padding-left: 10px;
    
    > span {
        > span {
            text-transform: uppercase;
            margin-right: 20px;
            font-family: Nasalization Rg;
            color: #939393;
        }
    }
    
    > div {
        padding: 5px 10px;
        background-color: #2D2D2D;
        border-radius: 7px;
        
        > p {
            display: flex;
            align-items: center;
            font-size: 18px;
            
            > div {
                width: 13px;
                height: 13px;
                background-color: #45FFA6;
                border-radius: 50%;
                margin-right: 10px; 
                animation: pulse 1300ms infinite ease-in-out; 
            }
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.match_layer1 {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.match_layer1_team {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.match_layer1_team_header {
    font-size: 22px;
    font-family: Nasalization Rg;
    color: #939393;
}

.match_layer1_team_name {
    font-size: 66px;
    font-weight: 900;
    transition: color 0.6s ease;
    
    &:hover {
        cursor: pointer;
        color: #10D4FE;
        font-size: 67px;
    }
}

.match_layer1_vs {
    font-family: Nasalization Rg;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.tournament_versus_part2.match {
    font-size: 66px;
    padding-top: 40px;
}

.match_layer1_unit {
    display: flex;
    flex-direction: column;
    width: 33%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.match_layer1_unit.vs {
    justify-content: space-between;
}

.tournament_versus_pic {
    height: 150px;
    width: 150px;
    object-fit: cover;
    border-radius: 50%;
}

.match_layer1_bo {
    font-size: 20px;
    color: white;

}

.match_layer1_count {
    font-size: 30px;
    color: #45FFA6;
    margin-top: 32px;
}

/* ------------------------- ТАБЫ ------------------------- */


.match_tabs {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 40px 0px 20px;
}

.matchtab {
    font-size: 18px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #45FFA6;
    color: white;
    padding: 3px 20px;
    font-weight: 900;
    margin-right: 10px;

    &:hover {
        color: rgb(26, 26, 26);
        background-color: #45FFA6;
    }
}

.matchtab.active {
    color: rgb(26, 26, 26);
    background-color: #45FFA6;
}

.matchtab_content {
    width: 100%;
    background-color: #2D2D2D;
    border-radius: 32px;
    padding: 26px;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.matchtab_content_header {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 900;
    border-bottom: 3px solid #939393;
    margin-bottom: 30px;
}

.matchtab_content_header_unit {
    width: 50%;
    margin-bottom: 10px;
    padding: 0 15px 0 15px;
}
.matchtab_content_header_unit span {
    color: #45FFA6;
}
.matchtab_content_header_unit.two {
    text-align: right;
}
.matchtab_content_layer{
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.matchtab_content_layer_team{
    display: flex;
    margin-top: 20px;
}
.matchtab_content_team{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.matchtab_content_team_name{
    font-size: 40px;
    font-weight: 900;
    &:hover {
        cursor: pointer;
        color: #10D4FE;
    }
}
.matchtab_content_team_status{
    font-size: 13px;
    color:#45FFA6;
    font-weight: 900;
}
.matchtab_content_team_status.loss{
    color:#FF334C;
}
.matchtab_content_team_status.draw{
    color:#ddc955;
}
.matchtab_content_team_count{
    font-size: 50px;
    font-weight: 900;
}
.matchtab_table{
    width: 80%;
}
.matchstream_button{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.matchstream_button.only {
    margin: 0;
    height: 40vh;
    align-items: center;
}