.team_history_container{
    text-align: left;
    margin-top: 50px;
}

.team_history_header {
    font-family: Nasalization Rg;
    color: #808080;
    font-style: italic;
    font-size: 20px;

    &:hover{
        color: #9e9e9e;
        cursor: pointer;
    }
}
.team_history_header span{
    margin-right: 5px;
}

.team_history_content{
    margin-top: 15px;
}