.no-select {
    user-select: none; 
    -webkit-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none;
}

.css-1fzpoyk {
    opacity: 1 !important;
    user-select: none; 
    -webkit-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none;
}

.myCarousel_item {
    width: 100%;
    height: 340px;
}