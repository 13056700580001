.userHeader_container {
    width: 650px;
    height: 185px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    /* Border */
    background:
        linear-gradient(#252525 0 0) padding-box,
        linear-gradient(to right, #10D4FE, #00FE88) border-box;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(#10D4FE, #00FE88) 30;
    border-radius: 10px;
    
    font-family: Nasalization Rg;
    position: relative;
    transition: width 0.5s ease, height 0.55s ease;
}
.userHeader_container.big {
    width: 900px;
    height: 330px;
}
.userHeader_container.streamer {
    width: 900px;
    height: 465px;
}
.userHeader_container.medium {
    width: 900px;
    height: 390px;
}

.userHeader_staticContainer {
    width: 600px;
    height: 140px;
    display: flex;
    justify-content: flex-start;
}


.userHeader_avatarContainer {
    width: 25%;
    border-radius: 50%;
    position: relative;
}
.userHeader_avatar {
    border-radius: 50%;
    width: 140px;
    height: 140px;
}

.userHeader_inputAvatar {
    visibility: hidden;
    position: absolute;
}

.userHeader_inputAvatar_upload {
    cursor: pointer;
    color: #45FFA6;
    font-size: 25px;
    position: absolute;
    left: 0px;
}

.userHeader_infoContainer {
    min-width: 35%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}

.badge{
    font-family: Proxima Nova Rg;
    font-size: 10px;
    padding: 3px 6px;
    cursor: pointer;
    background-color: #10D4FE !important;
    color: #222222;
    position: absolute;
    top: 0px;
    right: 0px;
}

.userHeader_userName {
    width: 100%;
    height: 25%;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: default;
    justify-content: space-between;
    position: relative;
}

.userHeader_socialLinksContainer {
    width: 100%;
    height: 35%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.userHeader_socialLink {
    padding: 4px;
    border: 1px solid #45FFA6;
    border-radius: 5px;
    cursor: pointer;
    
    & :is(.userHeader_linkIcon) {
        color: #fff;
        transition: color 500ms ease;
        &:hover {
            color: #10D4FE;
        }
    }
    
    & :is(.userHeader_linkIcon.disabled) {
        color: #b6b1b1;
    }
}

.userHeader_raitingContainer {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    & :is(span) {
        color: #45FFA6;
    }
    
    & :is(.userHeader_raitingTitle) {
        color: #BDBDBD;
        font-size: 10px;
    }
    
    & :is(.userHeader_raiting) {
        color: #fff;
        font-size: 18px;
    }
}

.userHeader_userTeamContainer {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 5%;
}

.userHeader_userTeamCard {
    min-width: 170px;
    padding: 10px;
    height: 60%;
    background-color: #363636;
    color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    transition: color 500ms ease;
    cursor: pointer;
    &:hover {
        color: #10D4FE;
    }
}
.userHeader_userTeamCardTitle {
    color: #949494;
    font-size: 10px;
}
.userHeader_userTeamName {
    width: 100%;
    text-align: start;
    font-size: 20px;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
}
.userHeader_userTeamName .disabled:hover {
    color: #fff;
}

.userHeader_expandIcon {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.userCard_fullUserINfoContainer {
    width: 100%;
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all 0.7s ease;
    padding: 0px 30px;
}

.userHeader_fullUserInfoContainer {
    width: 100%;
    height: 240px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}

.userHeader_infoBlock {
    width: 30%;
    height: 45px;
    margin: 1%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    & :is(span) {
        color: #A9A9A9;
        font-size: 10px;
    }
    
    & :is(div) {
        padding: 2% 3%;
        letter-spacing: 1px;
        color: #fff;
        background-color: #303030;
        border-radius: 5px;
        width: 100%;
        text-align: start;
    }
    
    & :is(input) {
        padding: 2% 3%;
        letter-spacing: 1px;
        color: #fff;
        background-color: #303030;
        border-radius: 5px;
        width: 100%;
        text-align: start;
        border: none;
        
        &:focus {
            background-color: #505050;
            outline: none;
            color: #ffffff;
        }
    }
}

.userHeader_infoBlock input[type="checkbox"] {
    cursor: pointer;
    margin-right: 10px;
    width: 1.2em;
    height: 1.2rem;
    accent-color: #45FFA6;
    border-radius: 10px;
    background-color: #303030 !important;
}

.userHeader_fullUserInfoContainer form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.userHeader_infoBlock.streamerCheckbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30%;
}

.userHeader_submitButton {
    width: 100%;
    text-align: center;
}
.submit_classic_button.userHeader {
    width: 30%;
    border-radius: 5px;
}

.userHeader_streamerLogo {
    padding: 3px 5px;
    background-color: #45FFA6 !important;
    color: #363636;
    font-size: 10px;
    height: 15px;
    margin-left: 5px;
}

.userHeader_socialIcon {
    background-color: #3D3D3D;
    border: 1px solid #45FFA6;
    border-radius: 5px;
    padding: 3px;
    cursor: pointer;
    position: relative;
}

.userHeader_socialIcon a {
    text-decoration: none;
    color: #fff;
    &:hover {
        color: #fff;
    }
}
.userHeader_socialIcon a:active,
.userHeader_socialIcon a:focus {
    text-decoration: none;
    color: #fff;
    &:hover {
        color: #fff;
    }
}

.userHeader_socialIcon.disabled {
    cursor: default;
    opacity: 0.5;
}

.userHeader_socials_tooltip_input {
    background-color: #3B3B3BDC;
    border: none;
    font-family: Nasalization Rg;
    width: 90%;
    text-decoration: none;
    &:focus {
        outline: none;
        color: #ffffff;
    }
}

/* В разработке */
.userCard_secondContainer_inDevelop {
    filter: blur(1.5px);
    transition: filter 0.3s ease;
    pointer-events: none;
    user-select: none; 
    width: 100%;
    height: 100%;
}
.userCard_secondContainer_inDevelopTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    z-index: 100;
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 7px;
}

.startProfileEditingButton_container {
    position: absolute;
    top: 21px;
    right: 21px;
    cursor: pointer;
}

.stopProfileEditingButton {
    transition: all 300ms ease;
    &:hover {
        color: rgba(202, 22, 22, 0.872);
    }
}

.startProfileEditingButton {
    padding: 5px 10px;
    border: 1px solid #45FFA6;
    border-radius: 10px;
    background-color: #45FFA6;
    color: #222222;
    font-size: 10px;
    font-family: Proxima Nova Rg;
    font-weight: bolder;
    transition: all 500ms ease;
    &:hover {
        background-color: transparent;
        color: #45FFA6;
        font-weight: normal;
    }
}