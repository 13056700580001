.tournaments_manage_tab_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.tournaments_manage_tab_container h4 {
    font-family: Nasalization Rg;
}
.current_tournaments_unit{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: rgb(68, 68, 68);
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 2px 3px 25px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 3px 25px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 3px 25px -5px rgba(0, 0, 0, 0.75);
    margin-top: 20px;
    min-height: 600px;
}
.current_tournaments_unit_table{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1200px;
}
.tournaments_manage_filter_container{
    display: flex;
    width: 60%;
    padding: 10px;
}
.tournaments_manage_filter{
    margin-left: 20px;
}
.current_tournaments_table {
    border-radius: 10px;
    width: 95%;
    text-align: center;
    table-layout: fixed;
}
.all_tourn_header{
    padding: 15px;
    font-size: 20px;
}
.current_tournaments_table_header{
    border-bottom: 1px solid rgb(198, 198, 198);
    height: 70px;
}
.current_tournaments_table_header.name {
    width: 20%;
}
.current_tournaments_table_td{
    border-bottom: 1px solid rgb(198, 198, 198);
    height: 50px;
}
.current_tournaments_table_td.name{
    cursor: pointer;
    &:hover{
        color: #45FFA6;
    }
}
.approved {
    color: green;
    font-weight: bold;
}
.not-approved {
    color: red;
    font-weight: bold;
}
.current_tournaments_pagination{
    margin-top: 15px;
}
.general_icon{
    cursor: pointer;
}