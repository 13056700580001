.news_container {
    width: 100%;
    
    display: flex;
    flex-direction: column;
}

.news_title {
    font-size: 60px;
    font-family: Nasalization Rg;
    
    margin-left: 2%;
    
    display: flex;
}

.news_content {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5%;
}


/*--------------------- NEWS SIZES ---------------------*/


.news_item_min {
    margin: 0.5%;
    height: 230px;
    flex-basis: 24%;
    margin: 0.5%;
}

.news_item_small {
    height: 230px;
    flex-basis: 32.3%;
    margin: 0.5%;
}

.news_item_medium {
    height: 230px;
    flex-basis: 49%;
    margin: 0.5%;
}

.news_item_big {
    height: 300px;
    flex-basis: 49%;
    margin: 0.5%;
}

.news_item_ultra {
    height: 230px;
    width: 100%;
}

.default {
    height: 230px;
    width: 100%;
    display: flex;    
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-family: Nasalization Rg;
    border-radius: 30px;
    box-shadow: 
        0px -3px 5px 0px rgba(0, 0, 0, 0.09),
        0px 12px 13px 0px rgba(0, 0, 0, 0.17),
        0px 4px 6px 0px rgba(0, 0, 0, 0.12),
        0px -12px 30px 0px rgba(0, 0, 0, 0.12),
        0px 54px 55px 0px rgba(0, 0, 0, 0.25);
    background-image: url('./NewsPhotos/background_radiance.png');
}