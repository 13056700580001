.soonEmpty_container {
    height: 100vh;
    display: flex;
    justify-content: center;
    pointer-events: none;
    padding-top: 11vh;
}

.soonEmpty {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.soonEmpty_text {
    font-family: MyFont Rg;
    letter-spacing: 1px;
    font-size: 300%;
}

.soonEmpty_text.propsText {
    font-family: Nasalization Rg;
    font-size: 300%;
    letter-spacing: none;
}