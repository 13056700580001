.BracketMatch {
    width: 140px;
    height: 60px;
    padding: 7px;
    border: 1px solid #7a7a80;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #201e1e;
    transition: border-color 0ms linear;
    justify-content: space-between;

    .BracketMatch_arrowReturnRightIcon {
        position: absolute;
        top: 0;
        left: -25px;
        color: #7a7a80;
    }

    .BracketMatch_arrowReturnRightIcon.selectedMatch_loser {
        transition: color 2000ms ease;
        animation: arrowColor 1500ms infinite;
    }

    .BracketMatch_arrowReturnRightUpIcon {
        color: #7a7a80;
        position: absolute;
        top: 30px;
        right: -25px;
        transform: rotate(270deg) translateX(18px);
    }

    .BracketMatch_number {
        font-size: 8px;
        position: absolute;
        top: -10px;
        left: 3px;
        background-color: #201e1e;
        padding: 2px 5px;
        border: 1px solid #7a7a80;
        border-radius: 4px;
        letter-spacing: 1px;
        transition: border-color 0ms linear;
        font-family: "Nasalization Rg";
    }

    .BracketMatch_date {
        width: 100%;
        color: #b2b2bb;
        font-size: 9px;
        letter-spacing: 0.5px;
        text-align: center;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
    }

    .BracketMatch_dateEdit {
        width: 100%;
        color: #b2b2bb;
        font-size: 10px;
        text-align: center;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
    }

    .BracketMatch_dateInput {
        width: 60%;
        color: #b2b2bb;
        font-size: 10px;
        text-align: center;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
        border: none;
        background-color: transparent;
        font-family: Proxima Nova Rg;
        outline: none;
    }
}

.changedMatch {
    border-color: yellow;
}

.BracketMatchLine {
    width: 100%;
    height: 1px;
    background-color: #7a7a80;
}

.selectedMatch {
    transition-delay: 400ms;
    transition: border-color 2000ms ease;
    border-color: #45ffa6;

    .BracketMatch_number {
        transition: border-color 500ms ease;
        transition-delay: 400ms;
        border: 1px solid #45ffa6;
    }
}
.selectedMatchOpponent {
    transition-delay: 400ms;
    transition: border-color 2000ms ease;
    border-color: #45ffa6;

    .BracketMatch_number {
        transition: border-color 500ms ease;
        transition-delay: 400ms;
        border: 1px solid #45ffa6;
    }
}
.selectedMatch_winner {
    transition-delay: 400ms;
    transition: border-color 2000ms ease;
    border-color: #45ffa6;

    .BracketMatch_number {
        transition: border-color 500ms ease;
        transition-delay: 400ms;
        border: 1px solid #45ffa6;
    }
}
.selectedMatch_loser {
    transition-delay: 400ms;
    transition: border-color 2000ms ease;
    border-color: red;

    .BracketMatch_number {
        transition: border-color 500ms ease;
        transition-delay: 400ms;
        border: 1px solid red;
    }
}
.selectedMatch_loser {
    animation: redBorder 1500ms infinite;

    .BracketMatch_number {
        animation: redBorder 1500ms infinite;
    }
}
.selectedMatch_winner {
    animation: greenBorder 1500ms infinite;

    .BracketMatch_number {
        animation: greenBorder 1500ms infinite;
    }
}

@keyframes arrowColor {
    0% {
        color: #7a7a80;
    }
    50% {
        color: red;
    }
    100% {
        color: #7a7a80;
    }
}

@keyframes redBorder {
    0% {
        border-color: #7a7a80;
    }
    50% {
        border-color: red;
    }
    100% {
        border-color: #7a7a80;
    }
}

@keyframes greenBorder {
    0% {
        border-color: #7a7a80;
    }
    50% {
        border-color: #45ffa6;
    }
    100% {
        border-color: #7a7a80;
    }
}