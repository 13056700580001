.warning_window_container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
}
.warning_window {
    margin-top: 200px;
    font-family: Nasalization Rg;
    width: 70%;
    border-radius: 7px;
    border: 1px solid red;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: #404040;
    font-size: clamp(13px, 2vw, 20px);
    justify-content: center;
    text-align: center;
}