.teamPlayersTab_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.teamPlayers_player_container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 50px;
}

.teamPlayers_player_logo_container {
    width: 25%;
    display: flex;
    align-items: center;
}

.teamPlayers_team_header_titles {
    margin: 0;
    padding: 0;
    font-size: 20px;
    margin-bottom: 10%;
    letter-spacing: 2px;
}

.teamPlayers_player_logo {
    width: 150px;
    height: 150px;
    background-color: #3c3c3c;
    border-radius: 50%;
    background-size: cover;
}

.teamPlayers_players_container {
    height: 100%;
    display: flex;
    align-items: center;
}

.teamPlayers_player_link {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    gap: 20px;
}

.teamCaptainImg {
    width: 30px;
}

.teamPlayers_player_socials {
    width: 42%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.teamPlayers_player_socials div:first-child {
    margin-left: 0;
}

.teamPlayers_player_socials_container {
    border: 0.5px solid #45FFA6;
    border-radius: 5px;
    background-color: #3c3c3c;
    width: 50%;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
}

.teamPlayers_player_socials_container a {
    color: #fff;
    text-decoration: 0;
}

.teamPage_team_tag {
    font-size: 11px;
    color: #939393;
}

.teamPlayers_player_stats_constainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 25%;
    align-items: flex-start;
}

.teamPlayer_player_title {
    margin: 0;
    padding: 0;
    font-size: 20px;
    margin-bottom: 10%;
    letter-spacing: 2px;
}

.teamPlayers_suppe_title {
    width: 100%;
    display: flex;
}

.teamPlayers_suppe_title_left {
    width: 40%;
    display: flex;
    margin-right: 5%;
}

.teamPlayer_player_info_title {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #D1D1D1;
}

.teamPlayer_suppe_title_right {
    width: 60%;
    display: flex;
}

.teamPlayers_player_info_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 25%;
    align-items: flex-start;
}

.teamPlayer_player_lastTournaments {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 25%;
    align-items: center;
}

.teamPlayers_suppe_title_left_mainInfo {
    width: 20%;
    display: flex;
    margin-right: 5%;
}

.teamPlayers_suppe_title_right_mainInfo {
    width: 80%;
    display: flex;
    margin-right: 5%;
}

.teamPlayers_line {
    width: 100%;
    color: #fff;
    margin-bottom: 3%;
    margin-top: 3%;
    border-top: 2px solid #fff;
}

.teamPlayers_player_mainContainer {
    width: 45%;
    display: flex;
    flex-direction: column;
}