.BracketMatch_opponent {
    font-family: Nasalization Rg;
    width: 100%;
    height: 49%;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: start;
    justify-content: space-between;

    > .BracketMatch_opponent_tag {
        align-content: start;
        transition: color 400ms ease;
        font-size: 12px;
        padding-left: 5px;
    }

    .BracketMatch_opponent_tag.selectedOpponent {
        color: #45ffa6;
    }
    > .BracketMatch_opponent_tag.hover {
        &:hover {
            color: #45ffa6;
        }
    }
    > .BracketMatch_opponent_tag.playerTeam {
        color: #10d4fe;
    }
    > .BracketMatch_opponent_result {
        width: 40%;
        height: 100%;
        font-size: 12px;
        border-left: 1px solid #7a7a80;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(1px);

        &::first-letter {
            text-transform: uppercase;
        }
        > .BracketMatch_opponent_result_cup {
            width: 14px;
        }
    }
    > .BracketMatch_opponent_result.winRes {
        color: #2de98e;
    }
    > .BracketMatch_opponent_result.lossRes {
        color: #d60505;
    }
    > .BracketMatch_opponent_result.defaultRes {
        color: #f1f1f1;
    }
}


.BracketMatch_opponent.lower {
    align-items: end;
    > .BracketMatch_opponent_tag {
        align-content: end;
    }
}

.BracketMatch_opponent > .react-tooltip {
    font-family: Nasalization Rg;
    font-size: 11px;
    border-radius: 4px;
    background-color: #2c2b2b;
    z-index: 10;
    opacity: 1;
}

.BracketMatch_opponent.isSwapOpponents {
    border: 1px dashed #45ffa6;
}