.lobby_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lobby_container_title {
    font-family: Nasalization Rg;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    margin: 0;
    cursor: pointer;
    transition: all 500ms ease;

    &:hover {
        color: #10D4FF;
    }
}

.lobby_buttons {
    display: flex;
    flex-direction: row;
}

.dota2_lobby_main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 550px;
}

.score-container {
    font-size: 16px;
    color: grey;
    font-family: Nasalization Rg;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.score-container span {
    font-size: 25px;
    color: rgb(222, 222, 222);
}

.dota_coinflow_container {
    text-align: center;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.dota2_lobby_button {
    min-height: 70px;
}

.dota_choice_container {
    display: flex;
    justify-content: space-around;
    font-family: Nasalization Rg;
    font-size: 16px;
    align-items: center;
    margin-top: 70px;
    height: 100px;
}

.dota_choice_container span {
    font-size: 26px;
    color: grey;
}

.dota_choice_side_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;
}

.dota_choice_side_container.active {
    min-width: 280px;
}

.dota_lobby_settings {
    font-size: 26px;
    margin-top: 60px;
    font-family: Nasalization Rg;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 360px;
}
.dota_lobby_adminsettings{
    font-family: Proxima Nova Rg;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
}
.dota_lobby_chosen_option{
    display: flex;
    justify-content: space-between;
}

.dota_choice {
    font-size: 20px;
    display: flex;
    align-items: center;
    margin: 0px 15px;
    background-color: transparent;
    justify-content: center;
    width: auto;
}

.neon_button {
    font-style: italic;
    color: #fff;
    animation: flicker 1.5s infinite alternate;
    text-transform: uppercase;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: rgb(129, 198, 123);
    }
}

.neon_button.inactive,
.neon_button.dire.inactive,
.neon_button.fp.inactive {
    visibility: hidden;
}

.neon_button::-moz-selection {
    color: #439f31;
}

.neon_button::selection {
    color: #439f31;
}

.neon_button:focus {
    outline: none;
}

@keyframes flicker {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {

        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem #439f31,
            0 0 4rem #439f31,
            0 0 6rem #439f31,
            0 0 8rem #439f31,
            0 0 10rem #439f31;

    }

    20%,
    24%,
    55% {
        text-shadow: none;
    }
}

.neon_button.dire {
    font-style: italic;
    color: #fff;
    animation: flickerdire 1.5s infinite alternate;
    animation-delay: 0.1s;
    text-transform: uppercase;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: rgb(250, 135, 135);
    }
}

.neon_button.dire::-moz-selection {
    color: #f40;
}

.neon_button.dire::selection {
    color: #f40;
}

.neon_button.dire:focus {
    outline: none;
}

@keyframes flickerdire {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {

        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem #f40,
            0 0 4rem #f40,
            0 0 6rem #f40,
            0 0 8rem #f40,
            0 0 10rem #f40;

    }

    20%,
    24%,
    55% {
        text-shadow: none;
    }
}

.neon_button.fp {
    font-style: italic;
    color: #fff;
    animation: flickerfp 1.5s infinite alternate;
    animation-delay: 0.2s;
    text-transform: uppercase;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: rgb(188, 217, 249);
    }
}

.neon_button.fp::-moz-selection {
    color: rgb(208, 208, 208);
}

.neon_button.fp::selection {
    color: rgb(208, 208, 208);
}

.neon_button.fp:focus {
    outline: none;
}

@keyframes flickerfp {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {

        text-shadow:
            -0.2rem -0.2rem 1rem rgb(143, 143, 143),
            0.2rem 0.2rem 1rem rgb(143, 143, 143),
            0 0 2rem rgb(143, 143, 143),
            0 0 4rem rgb(143, 143, 143),
            0 0 6rem rgb(143, 143, 143),
            0 0 8rem rgb(143, 143, 143),
            0 0 10rem rgb(143, 143, 143);

    }

    20%,
    24%,
    55% {
        text-shadow: none;
    }
}