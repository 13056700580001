.teamCard_team_container {
    position: relative;
    color: white;
    padding: 20px;
    overflow: hidden;
    border-radius: 16px;
}

.teamCard_team_background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-position: center;
    filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.5);
}

.teamCard_team_container_content {
    position: relative;
    z-index: 1;
    border-radius: 8px;
}

.teamCard_team_gameName {
    text-align: left;
    font-size: 30px;
    color: #808080;
    font-style: italic;
    margin-bottom: 20px;
}

.teamCard_team_container_content_header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
    }
}

.teamCard_team_container hr {
    margin-top: 50px;
}

.teamCard_team_title {
    text-align: center;
    cursor: pointer;
    transition: all 500ms ease;
    display: flex;

    &:hover {
        transform: scale(1.03);
        color: #10D4FF;
    }
}

.team_editButton.card {
    height: 30%;
    margin-left: 5px;
}

.teamCard_team_logo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.teamCard_players_grid {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
}

.teamCard_player_card {
    background-color: transparent;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    flex: 1 1 200px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 19%;
    z-index: 20;
    cursor: pointer;
    position: relative;

    >.teamCaptainImg {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    transition: all 500ms ease;

    &:hover {
        transform: scale(1.05)
    }
}

.teamCard_player_avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.teamCard_player_info>a {
    text-decoration: none;
    color: #0088cc;
}

.teamCard_player_info h4 {
    margin: 0;
    font-size: 1.1em;
    font-weight: bold;
}

.teamCard_player_info p {
    margin: 4px 0;
    font-size: 0.9em;
    color: #555;
}