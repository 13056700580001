/* AdminBotControlWidget.css */
.admin-bot-control-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 250px;
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

    .admin-bot-control-widget h4 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 16px;
        text-align: center;
    }

    .admin-bot-control-widget .btn {
        width: 100%;
        margin-bottom: 10px;
    }

        .admin-bot-control-widget .btn:last-child {
            margin-bottom: 0;
        }
