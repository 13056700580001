.reset_password_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    font-family: Nasalization Rg;
}

.modal_form.resetpassword {
    width: 35%;
}

.reset_button_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (max-width: 850px) {
    .modal_form.resetpassword {
        width: 90%;
    }
    
    h3 {
        font-size: 20px;
        line-height: 42px;
    }
    
    .modal_login_button {
        padding: 10px 0px;
        width: 100%;
    }
}