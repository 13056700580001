.tournaments_item_container {
    height: 100%;
    width: 250px;
    background-color: #30253B;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    overflow: hidden;
    margin-right: 10px;
    cursor: pointer;
    transition: transform 400ms ease-in-out;
}

.tournaments_item_container:hover {
    transform: scale(1.02);
}

.tournaments_item_main_info {
    padding: 5%;
    width: 100%;
    height: 160px;
}

.tournaments_item_main {
    font-family: Proxima Nova Rg;
    font-weight: 600;
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 45%;
}

.tournaments_item_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tournaments_item_info_title {
    width: 140px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 700ms ease;
    &:hover {
        color: #45FFA6;
    }
}

.tournaments_item_info p {
    font-weight: 600;
    color: #747373;
    font-size: 14px;
}

.tournaments_item_tournamentsImgSrc {
    height: 160px;
    width: 100%;
    filter: brightness(90%);
    object-fit: cover;
}

.tournaments_item_gameImgSrc {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 4%;
}

.tournaments_item_tags {
    font-family: Alien Energy Rg;
    color: #878484;
    font-size: 12px;
    text-transform: uppercase;
    width: 100%;
    margin-top: 4%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 30%;
    justify-content: flex-start;
    align-items: center;
}

.tag {
    background-color: #444;
    letter-spacing: 1px;
    padding: 0 3% 0 2%;
    margin-top: 2%;
    margin-right: 2%;
    height: auto;
    transform: skewY(4deg);
    transform: skewX(-17deg);
    border-radius: 3px;
}

.tag p {
    transform: skewY(-1deg);
    margin-bottom: 0;
    cursor: pointer;
}

.tournaments_item_slots {
    width: 100%;
    margin-top: 4%;
    display: flex;
    flex-direction: column;
}

.tournaments_item_slots .tournament_item_progress_bar {
    width: 100%;
    --bs-progress-height: 20px !important;
    --bs-progress-border-radius: 4px;
    border: 0;
    --bs-progress-box-shadow: var(--bs-box-shadow-inset);
    --bs-progress-bar-color: #444;
    --bs-progress-bar-bg: #45FFA6;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    overflow: hidden;
    background-color: #444;
    margin: 0;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition);
    box-shadow: 0px 0px 4px 0px #45FFA6;
}

.tournaments_item_slots_text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Nasalization Rg;
    font-size: 13px;
    margin-top: 1%;
    width: 100%;
}