.footerContainer {
    height: 20vw;
    width: 100%;
    padding: 5vw 5.83vw 3vw;
    font-family: "Tektur", serif;
    background: rgb(50, 50, 50);

    .footerContent {
        height: 75%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: start;

        img {
            width: 10vw;
        }

        .footerContentLinks {
            display: flex;
            gap: 4vw;

            .footerLinks.first {
                font-family: "Jura", serif;
                font-size: 0.9vw;
                display: flex;
                flex-direction: column;

                a {
                    color: #fff;
                    text-decoration: none;
                    transition: color 300ms ease;
                    cursor: pointer;

                    &:hover {
                        color: #45FFA6;
                    }
                }

                h5 {
                    color: #45FFA6;
                }
            }
        }
    }

    .footerContent.second {
        height: 25%;
        align-items: center;

        p {
            font-size: 1vw;
            color: #A0A0A0;
        }

        .footerContent_mediaIcons {
            display: flex;
            gap: 1.6vw;

            .footerContent_mediaIcon {
                height: 2vw;
                width: 2vw;
                cursor: pointer;
            }
        }
    }
}