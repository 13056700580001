.admin_container{
    background-color: #fdfdfd4b;
    min-height: 100vh;
    color: rgb(255, 254, 254);
    font-size: 16px;
    padding-top: 11vh;
}
.sidebar-wrapper{
    background-color: #3498ff;
    min-height: 100vh;
}