/*----- GOOEY BUTTON ----*/
.gooey_button {
    width: 25%;
    font-size: 20px;
    border-radius: 11px;
    background-color: #45FFA6;
    color: #000000;
    font-weight: 600;
    padding: 10px 5px;
    border: none;
    filter: url("#gooey");
    position: relative;
    cursor: pointer;
    transition: all 600ms ease;
    box-shadow: none;

    &:hover {
        color: rgb(71, 72, 71);
        box-shadow: 0px 5px 100px rgba(255, 255, 255, 0.4);
        font-size: 21px;
    }
}

@supports (-webkit-hyphens:none) {
    .gooey_button {
        filter: none;

        &:hover {
            box-shadow: none;
        }
    }
}

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        .gooey_button {
            filter: none;

            &:hover {
                box-shadow: none;
            }
        }
    }
}

.gooey_button:focus {
    outline: none;
}

.gooey_button .bubbles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.gooey_button .bubbles .bubble {
    background-color: #45FFA6;
    border-radius: 100%;
    position: absolute;
    display: block;
    z-index: -1;
}

.gooey_button .bubbles .bubble:nth-child(1) {
    left: 183px;
    width: 25px;
    height: 25px;
    animation: move-1 3.02s infinite;
    animation-delay: 0.2s;
}

.gooey_button .bubbles .bubble:nth-child(2) {
    left: 166px;
    width: 25px;
    height: 25px;
    animation: move-2 3.04s infinite;
    animation-delay: 0.4s;
}

.gooey_button .bubbles .bubble:nth-child(3) {
    left: 261px;
    width: 25px;
    height: 25px;
    animation: move-3 3.06s infinite;
    animation-delay: 0.6s;
}

.gooey_button .bubbles .bubble:nth-child(4) {
    left: 43px;
    width: 25px;
    height: 25px;
    animation: move-4 3.08s infinite;
    animation-delay: 0.8s;
}

.gooey_button .bubbles .bubble:nth-child(5) {
    left: 153px;
    width: 25px;
    height: 25px;
    animation: move-5 3.1s infinite;
    animation-delay: 1s;
}

.gooey_button .bubbles .bubble:nth-child(6) {
    left: 32px;
    width: 25px;
    height: 25px;
    animation: move-6 3.12s infinite;
    animation-delay: 1.2s;
}

.gooey_button .bubbles .bubble:nth-child(7) {
    left: 114px;
    width: 25px;
    height: 25px;
    animation: move-7 3.14s infinite;
    animation-delay: 1.4s;
}

.gooey_button .bubbles .bubble:nth-child(8) {
    left: 195px;
    width: 25px;
    height: 25px;
    animation: move-8 3.16s infinite;
    animation-delay: 1.6s;
}

.gooey_button .bubbles .bubble:nth-child(9) {
    left: 33px;
    width: 25px;
    height: 25px;
    animation: move-9 3.18s infinite;
    animation-delay: 1.8s;
}

.gooey_button .bubbles .bubble:nth-child(10) {
    left: 233px;
    width: 25px;
    height: 25px;
    animation: move-10 3.2s infinite;
    animation-delay: 2s;
}

@keyframes move-1 {
    0% {
        transform: translate(0, 0);
    }

    99% {
        transform: translate(0, -97px);
    }

    100% {
        transform: translate(0, 0);
        opacity: 0;
    }
}

@keyframes move-2 {
    0% {
        transform: translate(0, 0);
    }

    99% {
        transform: translate(0, -92px);
    }

    100% {
        transform: translate(0, 0);
        opacity: 0;
    }
}

@keyframes move-3 {
    0% {
        transform: translate(0, 0);
    }

    99% {
        transform: translate(0, -71px);
    }

    100% {
        transform: translate(0, 0);
        opacity: 0;
    }
}

@keyframes move-4 {
    0% {
        transform: translate(0, 0);
    }

    99% {
        transform: translate(0, -72px);
    }

    100% {
        transform: translate(0, 0);
        opacity: 0;
    }
}

@keyframes move-5 {
    0% {
        transform: translate(0, 0);
    }

    99% {
        transform: translate(0, -88px);
    }

    100% {
        transform: translate(0, 0);
        opacity: 0;
    }
}

@keyframes move-6 {
    0% {
        transform: translate(0, 0);
    }

    99% {
        transform: translate(0, -122px);
    }

    100% {
        transform: translate(0, 0);
        opacity: 0;
    }
}

@keyframes move-7 {
    0% {
        transform: translate(0, 0);
    }

    99% {
        transform: translate(0, -102px);
    }

    100% {
        transform: translate(0, 0);
        opacity: 0;
    }
}

@keyframes move-8 {
    0% {
        transform: translate(0, 0);
    }

    99% {
        transform: translate(0, -99px);
    }

    100% {
        transform: translate(0, 0);
        opacity: 0;
    }
}

@keyframes move-9 {
    0% {
        transform: translate(0, 0);
    }

    99% {
        transform: translate(0, -126px);
    }

    100% {
        transform: translate(0, 0);
        opacity: 0;
    }
}

@keyframes move-10 {
    0% {
        transform: translate(0, 0);
    }

    99% {
        transform: translate(0, -80px);
    }

    100% {
        transform: translate(0, 0);
        opacity: 0;
    }
}