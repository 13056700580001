.BracketMatch_updatedMenu {
    width: 220px;
    padding: 15px;
    position: absolute;
    right: -225px;
    top: 0;
    background-color: #201e1e;
    border: 1px solid #7a7a80;
    border-radius: 4px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 15px;

    button {
        width: 100%;
        height: 25px;
        font: 10px Nasalization Rg;
        cursor: pointer;
        background-color: #45ffa6;
        border-radius: 4px;
        color: #201e1e;
        border: none;
        padding: 5px;
        &:hover {
            background-color: #35e18e;
        }
    }
    .BracketMatch_updatedMenu_backArrowContainer {
        width: 100%;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-radius: 4px;

        .BracketMatch_updatedMenu_backArrow {
            width: 30%;
            height: 20px;
            font-size: 20px;
            color: #201e1e;
            background-color: #45ffa6;
            border-radius: 4px;
            &:hover {
                background-color: #35e18e;
            }
        }

        button {
            width: 65%;
            height: 20px;
            border-radius: 4px;
            color: #201e1e;
            background-color: #45ffa6;
            border: none;
            font: 12px "Nasalization Rg";
            cursor: pointer;
            &:hover {
                background-color: #35e18e;
            }
        }
    }

    .BracketMatch_updatedMenu_backArrowContainer.date {
        .BracketMatch_updatedMenu_backArrow {
            width: 100%;
        }
    }

    .BracketMatch_updatedMenu_date {
        width: 100%;
        display: flex;
        justify-content: space-between;

        input {
            width: 75%;
            height: 25px;
            font: 9px "Nasalization Rg";
            background-color: transparent;
            border: 1px solid #45ffa6;
            border-radius: 4px;
            color: #f1f1f1;
            text-align: justify-all;
            outline: none;
            padding: 5px;
            &:focus {
                border: 1px solid #35e18e;
            }
        }
        input::-webkit-calendar-picker-indicator {
            background-color: #45ffa6;
            border-radius: 1px;
            cursor: pointer;
        }

        button {
            height: 24px;
            width: 20%;
            font: 9.5px "Nasalization Rg";
            border-radius: 4px;
            background-color: #45ffa6;
            border: none;
            color: #201e1e;
            cursor: pointer;
            &:hover {
                background-color: #35e18e;
            }
        }
    }

    .BracketMatch_updatedMenu_score {
        width: 100%;
        display: flex;
        justify-content: space-between;

        span {
            width: 55%;
            font: 13px "Nasalization Rg";
        }

        div {
            width: 45%;
            display: flex;
            justify-content: space-between;

            span {
                width: 45%;
                background-color: transparent;
                color: #45ffa6;
                font: 10px "Nasalization Rg";
                border-radius: 4px;
                padding: 5px;
                cursor: pointer;
                border: 1px solid #45ffa6;
                text-align: center;
            }

            input {
                width: 45%;
                font: 10px "Nasalization Rg";
                background-color: transparent;
                border: 1px solid #45ffa6;
                border-radius: 4px;
                color: #f1f1f1;
                text-align: center;
                outline: none;
                padding: 3px;
                &:focus {
                    border: 1px solid #35e18e;
                }
            }
        }
    }
}