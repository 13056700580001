.partnerPage_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-top: 11vh;
}

.partnerPage_header {
    width: 100%;
    height: 30vh;
    display: flex;
    margin-bottom: 40px;
}

.partnerPage_headerLogo_container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
}
.partnerPage_img{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    object-fit: contain;
}
.partnerPage_headerLogo {
    background-color: #313131;
    width: 80%;
    height: 100%;
    border-radius: 30px;
    border: 2px solid #45FFA6;
}

.partnerPage_headerInfo_container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6%;
}

.partnerPage_miniinfo_container{
    margin-top: 15px;
}
.partnerPage_miniinfo_unit{
    display: flex;
}
.partnerPage_description{
    font-size: 20px;
}
.partnerPage_miniheader{
    width: 30%;
    color:rgb(97, 97, 97);
    font-weight: 700;
    font-size: 14px;
}
.partnerPage_headerInfo_desc h3 {
    font-family: MyFont Rg;
    letter-spacing: 1px;
    font-size: 45px;
    color: #fff;
    
    width: 100%;
    
    margin: 0;
}


.partnerPage_headerInfo_desc span:first-child {
    font-family: Proxima Nova Rg;
    letter-spacing: 0.5px;
    color: #989393;
    
    width: 100%;
    
    margin: 0 5% 0 0;
}

.partnerPage_headerInfo_desc span:last-child {
    font-family: Proxima Nova Rg;
    letter-spacing: 1px;
    color: #fff;
    
    width: 100%;
    
    margin: 0;
}

.partnerPage_headerInfo_container p {
    font-family: Proxima Nova Rg;
    letter-spacing: 0.5px;
    color: #fff;
    
    text-align: justify;
    text-justify: inter-word;
    
    width: 100%;
    
    margin: 0;
}


/*-------------- MAIN --------------*/


.partnerPage_main {
    width: 100%;
    height: auto;
    margin-bottom: 10%;
}


/*-------------- NEWS --------------*/


.partnerPage_main_news_container {
    width: 100%;
    height: 500px;
    display: flex;
    margin-bottom: 20%;
}


/*-------------- TOURNAMENTS --------------*/


.partnerPage_TournamentsCarousel_container {
    width: 100%;
    margin-bottom: 5%;
}

.partnerPage_TournamentsCarousel_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8%;
    margin-bottom: 4%;
}

.partnerPage_TournamentsCarousel_header h3 {
    width: 40%;
    font-size: 50px;
    font-family: Nasalization Rg;
    margin: 0;
}

.partnerPage_TournamentsCarousel_tabs {
    width: 40%;
    display: flex;
    gap: 5%;
}

.partnerPage_TournamentsCarousel_tab {
    font-family: Nasalization Rg;
    color: #fff;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    
    border-radius: 10px;
    border: 1px solid #45FFA6;
    
    box-shadow: 0px 0px 4px 0px #45FFA6;
    
    background-color: #222222;
    font-weight: 500;
    padding: 5px 10px;
    &:hover {
        background-color: #45FFA6;
        color: #222222;
        font-weight: bold;
    }
}

.partnerPage_TournamentsCarousel_tab.active {
    background-color: #45FFA6;
    color: #222222;
    font-weight: 600;
}


/*-------------- TOURNAMENTS TAB-CONTENT --------------*/


.partnerPage_tabs_content_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.partnerPage_tabs_content {
    width: 100%;
}


/*-------------- TEAMS --------------*/


.partnerPage_teams_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.partnerPage_teams_container h3 {
    width: 40%;
    font-size: 50px;
    font-family: Nasalization Rg;
    margin: 0;
}

.partnerPage_teams {
    width: 100%;
    display: flex;
}