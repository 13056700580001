.mapSelection_container {
    padding: 11vh 7%;
    font-family: Proxima Nova Rg;
}

.mapselection_tournamentname{
    font-size: 32px;
    font-weight: 900;
    color:#8d8d8d;
    font-style: italic;
}

.mapSelection_mainContant {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5%;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
}


/*--------------------- TEAMS ---------------------*/


.teams_team {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.teams_name {
    letter-spacing: 1px;
    margin-bottom: 5%;
    text-align: end;
    width: 100%;
    font-size: 20px;
    color: #10D4FF;
    &:hover{
        cursor: pointer;
    }
}

.teams_name.secondTeam {
    text-align: start;
}

.teams_container {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


/*--------------------- PLAYERS ---------------------*/


.teams_players {
    width: 100%;
    gap: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.teams_player_container {
    width: 90%;
    height: 90px;
    display: flex;
    background-color: #343434;
    border-radius: 7px;
    justify-content: space-between;
    align-items: center;
    padding: 3%;
    border: 1px solid red;
    position: relative;
}

.teams_player_container.firstTeam {
    flex-direction: row-reverse;
}

.teams_player_container.ready {
    border: 1px solid #00FE88;
}

.teams_team_standinsIcon_firstTeam {
    font-size: 10px;
    position: absolute;
    left: 7px;
    top: 7px;
    background-color: #45FFA6;
    border-radius: 10px;
    color: #222;
    padding: 1px 5px;
    font-family: Nasalization Rg;
}
.teams_team_standinsIcon_secondTeam {
    font-size: 10px;
    position: absolute;
    right: 7px;
    top: 7px;
    background-color: #45FFA6;
    border-radius: 10px;
    color: #222;
    padding: 1px 5px;
    font-family: Nasalization Rg;
}

.teams_player_avatarContainer {
    width: 30%;
    border-radius: 50%;
}

.teams_player_avatarContainer.firstTeam {
    text-align: end;
}

.teams_player_avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #10D4FF;
    object-fit: cover;
}

.teams_player_nameContainer {
    width: 50%;
    text-align: center;
    letter-spacing: 1px;
}

.teams_player_nameContainer h3 {
    font-size: 20px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%; 
    &:hover {
        cursor: pointer;
        color: #10D4FF;
    }
}

.teams_player_isCaptainContainer {
    width: 20%;
    text-align: center;
}

.teams_player_isCaptainContainer img {
    width: 40%;
}

.isConnected_container {
    width: 10%;
    color: red;
}

.isConnected_container.secondTeam {
    width: 10%;
    color: red;
    text-align: end;
}

.isConnected_container.connected {
    color: #00FE88;
    width: 10%;
}


/*--------------------- MAPS ---------------------*/


.maps_container {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.maps {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 13px;
}

.map {
    width: 70%;
    height: 90px;
    border-radius: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid silver;
}

.map.pick {
    border: 2px solid red;
}

.map.game {
    border: 2px solid #00FE88;
}

.maps_title_container {
    margin-bottom: 5%;
    letter-spacing: 1px;
    text-align: center;
    height: 130px;
}

.map.captain {
    cursor: pointer;
    transition: all 500ms ease;
    &:hover {
        border: 2px solid #00FE88;
    }
}

.mapselection_ready_buttonContainer {
    width: 30%;
    text-align: center;
}

.mapselection_ready_button {
    width: 50%;
    border: 1px solid #45FFA6;
    font-size: 22px;
    font-family: Proxima Nova Rg;
    text-align: center;
    color: #fff;
    font-weight: 300;
    background-color: transparent;
    border-radius: 7px;
    letter-spacing: 1px;
    padding: 1.5%;
    margin-top: 100px;
    transition: all 500ms ease;
    &:hover {
        background-color: transparent;
        color: #fff;
    }
}


/* --------- serverReady_modal --------- */


.serverReady_modal_body {
    width: 100%;
    height: 100%;
    background-image: url(../../assets//img//modal_background_radiance.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 7%;
    border-radius: 10px;
    font-family: Nasalization Rg;
}

.mapselection_ready_button.ready {
    background-color: #00FE88;
    color: #000;
    font-weight: bold;
}

.serverReady_modal_title {
    margin: 0;
    margin-bottom: 5%;
    text-align: center;
}

.serverReady_modal_mainInfo {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 5%;
}

.server_not_ready_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.serverReady_modal_body hr {
    width: 100%;
    color: #00FE88;
    height: 2px;
    margin: 0;
}

.maps_title_chooser {
    color: #45FFA6;
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    text-transform: uppercase;
}

.maps_title_chooser span {
    color: #10D4FF;
}

.maps_ban_banTimer {
    font-size: 22px;
}

.maps_ban_banTimer span {
    color: red;
    font-weight: bold;
}

.maps_ban_banTimer span {
    font-size: 25px;
}

.server_info {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
}

.server_connect_address {
    background-color: rgba(100, 100, 100, 0.842);
    border: 1px solid rgb(39, 39, 39);
    margin: 15px 0px;
    border-radius: 10px;
    padding: 5px 5px 5px 20px;
    -webkit-box-shadow: inset 1px 1px 18px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 1px 1px 18px -8px rgba(0, 0, 0, 0.75);
    box-shadow: inset 1px 1px 18px -8px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
}

.game_maps {
    font-size: 32px;
    font-style: normal;
    font-weight: 1000;
    color: #FFF;
    width: 300px;
    height: 125px;
    border-radius: 10px;
    margin: 1%;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    border: 3px solid rgb(23, 201, 0);
    background-color: rgba(0, 0, 0, 0.);
    cursor: default;
    position: relative;
}

.server_ready_container a {
    border: 1px solid #45FFA6;
    font-size: 18px;
    font-family: Nasalization Rg;
    font-weight: normal;
    text-align: center;
    color: #000;
    background-color: #00FE88;
    border-radius: 5px;
    padding: 7px 12px;
    transition: all 500ms ease;
    &:hover {
        background-color: transparent;
        color: #45FFA6;
        text-decoration: none;
    }
}

.lobby_connect_button {
    text-decoration: none;
    color: #252525;
    font-size: 20px;
    font-family: Proxima Nova Rg;
    font-weight: 600;
    background-color: #45FFA6;
    border: 1px solid #45FFA6;
    border-radius: 10px;
    padding: 1.5% 5%;
    cursor: pointer;
    margin-top: 20px;
    transition: all 350ms ease-in-out;
}
.clipboard_icon{
    cursor: pointer;
    color: white;
}

/* --------- additional_lobby_members --------- */

.additional_lobby_members {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-around;
}

.lobby_members {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.lobby_members_map {
    display: flex;
    flex-direction: column;
}

.lobby_members_title {
    font-size: 25px;
    letter-spacing: 1px;
}

.lobby_memderContainer {
    width: 100px;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
}

.lobby_memderImgContainer {
    border: 1px solid red;
    border-radius: 50%;
}

.lobby_memderImgContainer.ready {
    border: 1px solid #00FE88;
}

.lobby_memderImg {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-position: center;
    object-fit: cover;
}

.lobby_memderName {
    font-size: 15px;
    margin-left: 5px;
}

.recylcleButton {
    position: absolute;
    right: 20px;
    background-color: #fff;
    color: black;
    padding: 2px;
    border-radius: 3px;
}

.serverModalButton {
    padding: 5px 10px;
    background-color: #00FE88;
    color: #000;
    border: 1px solid #00FE88;
    border-radius: 5px;
    transition: all 500ms ease;
    margin-top: 30px;
    font-family: Nasalization Rg;
    &:hover {
        color: #00FE88;
        background-color: transparent;
    }
}
.old_lobby{
    width: 100%;
    font-family: Nasalization Rg;
    margin-top: 50px;
    font-size: 36px;
}